
import { UI_LOCATION_BAR_PROPS, UI_COLOR, UI_BORDER } from '../config/uiSettings';
import {  Component } from 'react'
import { Canvas } from '@react-three/fiber'
import {Stats} from '@react-three/drei'
import SystemScene from './scenes/SystemScene';
import BodyScene from './scenes/BodyScene';
import GalaxyScene from './scenes/GalaxyScene';
import { Galaxy } from '../objects/Galaxy';
import { System } from '../objects/System';
import { Planet } from '../objects/Planet';
import { Area } from '../objects/Area';
import GroundScene from './scenes/GroundScene';
import PlanetCreatorScene from './scenes/UtilsScene/PlanetCreatorScene';
import AreaCreatorScene from './scenes/UtilsScene/AreaCreatorScene';
import CanvasGUI from './utils/CanvasGUI';
import ComponentWidget from './utils/widgets/ComponentWidget';
import ResourceComponent from './interface/resources/ResourceComponent';
import DraggableFrame from './utils/DraggableFrame';
import PageWidget from './utils/widgets/PageWidget';
import GravSimScene from './scenes/UtilsScene/GravSim/GravSimScene';
import GravSimSceneGPU from './scenes/UtilsScene/GravSim/GravSimGPU/GravSimSceneGPU';

//This component is the root of the view
export default class ParentView extends Component {
    constructor(props){
        super(props)
        this.props = props;
        this.state = {
            object: /**props.galaxy*/"GRAV_SIM_GPU",//.systems[0].objects[3].areas[0],
            children: []
        }
        this.addChild(
            {/**<CanvasGUI type={"CANVAS"} key={"PATCHNOTE"} id={"PATCHNOTE"}>
                <DraggableFrame position={[2.*window.innerHeight/6,10+window.innerHeight/6]} removeChild={this.removeChild} title={"INTRO"} id={"PATCHNOTE"}>
                    <PageWidget>

                        <div style={{width:window.innerHeight/2, height: window.innerHeight/2}}>
                            <p>Ce site permet de <b>générer des terrains et la carte d'élévation</b> correspondante.</p>
                            <p>Il a été mis en ligne suite à la demande de quelques proches. Il n'est pas complet et ne permet pas d'aller bien loin (pour le moment). 
                                Il manque par exemple <b>l'érosion</b> qui devrait arriver dans les prochaines semaines.
                            </p>
                            <p>
                                La partie <b>AREA/REGION</b> est optimisée un minimum, c'est à dire que la carte d'élévation est calculée au début sur GPU (GPGPU) puis simplement retranscrite au temps d'après.
                                Ce qui fait que le calcul ne se fait qu'au début ou à chaque changement de paramètre de génération.
                            </p>
                            <p>
                                Alors que la partie <b>PLANET/PLANÈTE</b> ne l'est pas et est calculée chaque image, ce qui peut donc être très lent sur de petites configurations. Cela vient du fait que cette partie a 
                                été faite avant la partie "région" et qu'elle ne sert qu'à moi pour un projet plus grand, elle n'est donc ici que par loisir.
                            </p>
                            <p>
                                <b>TO DO</b>: EROSION; + de paramètres; système de blueprint pour meilleur personnalisation plutôt que de changer quelques vulgaires paramètres et pas la logique derrière. 
                            </p>
                        </div>

                        <div style={{width:window.innerHeight/2, height: window.innerHeight/2}}>
                            <p><b>Patch Note 06/01</b>:
                                <p>
                                    -Cliquer sur la heightmap ouvre une fenêtre avec l'image en plus grande
                                <br /> -Sauvegarde de la zone en JSON 
                                <br />  -Quelques paramètres de génération supplémentaires
                                </p>
                            </p>
                            <p><b>Bugs</b>:
                                <p>
                                    -Pour certaines combinaisons de paramètres le rendu se désactive, pas la heightmap et les assets...
                                </p>

                            </p>
                        </div>
                        
                    </PageWidget>
                </DraggableFrame>
            </CanvasGUI>**/}
        )
    }

    //Remove a child view using his id 
    removeChild = (id) => {
        this.state.children.forEach(child => {
            if(child.props.id == id && this.state.children.indexOf(child) > -1){this.state.children.splice(this.state.children.indexOf(child),1);}
        });
        this.setState(this.state);
    }

    //Don't forget to pass a prop:"key=YOUR_ID" and "id=YOUR_ID" in the JSX element
    addChild = (child) => {
        let flag = false;
        this.state.children.forEach(child2 => {
            if(child2.props.id == child.props.id){
                flag = true;
                return;
            }
        });
        if(flag){return;}
        this.state.children.push(child);
        this.setState(this.state);
    }

    /*A child can have a different type from another
     "CANVAS" : the child can only appear inside a canvas element
    "GLOBAL" : the child can only appear outside a canvas element */
    getChildren = (type) => {
        if(type == undefined){return(<>{this.state.children}</>)}

        let rebuild_children = []
        this.state.children.forEach(child => {
            if(child.type !== undefined && child.props.type == type){
                rebuild_children.push(child)
            }
        });
        return(<>{rebuild_children}</>)
    }

    setScene = (object) => {
        this.state.object = object;
        this.setState(this.state);
    }

    render(){ 
        return(
            <div style={{width:"100%", height:"100%"}}>
                <div style={{backgroundColor:UI_COLOR.opaque_background, borderBottom:UI_BORDER , width:"100%", height:"3%"}}>
                    <div style={{padding:"0em 0.5em", float: "left", color:UI_COLOR.title, borderRight:"2px solid "+UI_COLOR.border}}
                        onClick={()=>{
                            this.setScene("PLANET_CREATOR");
                        }}>
                        <b>{"PLANET CREATOR"}</b>
                    </div>
                    <div style={{padding:"0em 0.5em", float: "left", color:UI_COLOR.title, borderRight:"2px solid "+UI_COLOR.border}}
                        onClick={()=>{
                            this.setScene("AREA_CREATOR");
                        }}>
                        <b>{"AREA CREATOR"}</b>
                    </div>
                </div>
                <div style={{width:"100%", height:"97%"}}>
                <Canvas >
                    {(this.state.object == "GRAV_SIM") && <GravSimScene addChild={this.addChild} removeChild={this.removeChild} />}
                    {(this.state.object == "GRAV_SIM_GPU") && <GravSimSceneGPU addChild={this.addChild} removeChild={this.removeChild} />}
                    {(this.state.object == "PLANET_CREATOR") && <PlanetCreatorScene />}
                    {(this.state.object == "AREA_CREATOR") && <AreaCreatorScene addChild={this.addChild} removeChild={this.removeChild} />}
                    {/**this.state.object instanceof Galaxy && <GalaxyScene galaxy={this.state.object} removeChild={this.removeChild} addChild={this.addChild} setScene={this.setScene}/>}
                    {this.state.object instanceof System && <SystemScene system={this.state.object} removeChild={this.removeChild} addChild={this.addChild} setScene={this.setScene}/>}
                    {this.state.object instanceof Planet && <BodyScene body={this.state.object}  removeChild={this.removeChild} addChild={this.addChild} setScene={this.setScene}/>}
        {this.state.object instanceof Area && <GroundScene area={this.state.object} removeChild={this.removeChild} addChild={this.addChild} setScene={this.setScene}/>*/}
                    {this.getChildren("CANVAS")}   
                </Canvas>
                </div>
                {this.getChildren("GLOBAL")}  

                {<Stats />}
                <LocationBarUI setScene={this.setScene} object={this.state.object}/>
            </div>
        )
    }
}

function LocationBarUI(props){

    let rows = [];

    let i = 0;
    let obj = props.object;
    while(obj.parent != undefined){

        obj = obj.parent;
        
        rows.push(
            <LocationBarObjectUI object={obj} setScene={props.setScene} key={i}/>
        )
        i++;
    }
    rows.reverse()

    return(
      <div style={{userSelect:"none",
         color:"white",
         zIndex:2,
         position:"absolute",
         bottom:0,
         height:UI_LOCATION_BAR_PROPS.size[1], 
         backgroundColor:UI_COLOR.background
         }}> 
         {rows}
      </div>
    )
  
  }

  function LocationBarObjectUI(props){

    return(
            <div style={{padding:"0em 0.5em", float: "left", color:UI_COLOR.title, borderRight:"2px solid "+UI_COLOR.border}}
            onClick={()=>{
                props.setScene(props.object)
            }}>
            <b>{props.object.constructor.name.charAt(0).toUpperCase()+props.object.constructor.name.slice(1)}</b>
            </div>
    )
  }
  