import { useRef } from "react";
import {  useFrame, extend } from "@react-three/fiber";
import * as THREE from 'three'
import { LayerMaterial } from "lamina";
import SkyboxShader from "./shaders/SkyboxShader"; 
import SkyboxStarsMaterial from "./shaders/SkyboxStarsMaterial";
extend({SkyboxShader})


export default function SystemSkybox(props){
    let reference = props.reference ? props.reference : null;

    let systems_wout_ref = [...reference.parent.systems.getSystems()];
    systems_wout_ref.splice(systems_wout_ref.indexOf(reference),1);

    let size = props.size ?? (reference !== null ? reference.objects[reference.objects.length-1].distance*700/(reference.getAstronomicalUnit()/100) : 10);

    let sun_position = new THREE.Vector3(
        Math.round(1000*Math.cos(props.planet.angle ?? Math.PI/2))/1000,
        Math.round(1000*Math.sin(props.planet.angle ?? 0))/1000,
        0).multiplyScalar(-1);

    let planets_wout_ref = [...reference.objects];
    planets_wout_ref.splice(0,1);
    planets_wout_ref.splice(planets_wout_ref.indexOf(props.planet),1);
    let planets_position = {};
    planets_wout_ref.forEach(planet => {
        planets_position["planetsPosition"+planets_wout_ref.indexOf(planet)] = new THREE.Vector3(
            Math.cos(planet?.angle),
            Math.sin(planet?.angle),
            0).add(sun_position);
    });


    const materialRef = useRef()

    useFrame((state) => {
        const { clock } = state;
        materialRef.current.time = clock.getElapsedTime();
    });

    return(
        <>
            <mesh rotation={[0,0,0]} scale={size}>
                <icosahedronGeometry args={[1., 120]} />
                <LayerMaterial lighting="basic" fog={false} flatShading={false} side={THREE.BackSide}>
                    <skyboxShader ref={materialRef} time={0.0} sunPosition={sun_position} sunColor={props.sunColor} {...planets_position} planetsCount={props.planetsCount ?? planets_wout_ref.length}/>
                </LayerMaterial>
            </mesh>
            {/*<mesh rotation={[0,0,0]} scale={size*.99}>
                <icosahedronGeometry args={[1., 120]} />
                <SkyboxStarsMaterial stars={systems_wout_ref.map((sys)=>{return sys.position.clone().sub(reference.position.clone())})} />
    </mesh>**/}
        </>
    )

}