import { Seed } from './utils/Seed.js';

export class Area {

    constructor(host, data){

        this.parent = host;

        this.latitude = data["latitude"];
        this.longitude = data["longitude"];
        this.seed = new Seed(data["seed"]);

    }


}