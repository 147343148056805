import {Color} from 'three'

export const SUN_DATA = {
    mass: 1.98892e30, //kg
    luminosity: 3.8275e26, //W
    radius: 696340e3, //m
    temperature: 5772, //K  , Photosphere temperature, not the core
}

export const EARTH_DATA = {
  mass: 5.9736e24, //kg
  radius: 6371008, //m
}

export const STEFAN_BOLTZMANN = 5.670374e-8; // W/(m*m)/(K*K*K*K)
export const ASTRONOMICAL_DISTANCE = 149.6e9; //m

//Map Temperature : Color
function loadBlackBodyColors() {
    var result = null;
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.open("GET", "../blackbody_colors.txt", false);
    xmlhttp.send();
    if (xmlhttp.status==200) {
      result = xmlhttp.responseText;
    }

    if(result == null)return result;

    let lines = result.split('\n');
    let exp = {};
    lines.forEach(line => {
        let words = line.split(' ');
        exp[parseInt(words[0])] = new Color(words[1].replace(/\s+/g,""));
    });

    return exp;
  }

export const BLACKBODY_COLORMAP = loadBlackBodyColors();