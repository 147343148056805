import { Line, OrbitControls, useTexture} from '@react-three/drei'
import * as THREE from 'three'
import { useFrame, useThree} from '@react-three/fiber'
import { useEffect, useMemo ,useRef, Component, useState, Suspense, useLayoutEffect } from 'react'
import { clamp, getColorByHisTemp, getPointsOnACircle } from '../../utils'
import { SUN_DATA, EARTH_DATA } from '../../config/physicsData'
import { Planet } from '../../objects/Planet'
import { Star } from '../../objects/Star'
import { Moon } from '../../objects/Moon'
import {AsteroidBelt} from '../../objects/AsteroidBelt'
import SystemSkybox from './SystemScene/SystemSkybox'
import SystemSelection from './SystemScene/SystemSelection'
import { BodyView } from './BodyScene/BodyView'
import {AsteroidBeltView} from './BodyScene/AsteroidBelt/AsteroidBeltView'
import { Asteroid } from '../../objects/Asteroid'


import AutoWindow from '../utils/AutoWindow'
import CanvasGUI from '../utils/CanvasGUI'
import ComponentWidget from '../utils/widgets/ComponentWidget'

//List of JSX Planets components 
function getObjects(system, setObjectSelected, objectSelected){
    const rows = [];
    for (let i = 0; i < system.objects.length; i++) {
        if(system.objects[i] instanceof Planet){
            let planet = system.objects[i];
            let size = planet.radius/EARTH_DATA.radius
            let position = new THREE.Vector3(
                planet.distance/(system.getAstronomicalUnit()/100) * Math.cos(planet.angle),
                planet.distance/(system.getAstronomicalUnit()/100) * Math.sin(planet.angle),
                0)
            const moon_rows = [];
            for(let j=0; j < planet.objects.length; j++){
              if(planet.objects[j] instanceof Moon){
                let moon = planet.objects[j];
                let moon_size = 0.25;
                let moon_position = moon.getPosition("PLANET").clone().divideScalar(system.getAstronomicalUnit()/250).add(position);
                let normal = moon_position.clone().sub(position).normalize().multiplyScalar(size*1.5);
                moon_position.add(normal);
                moon_rows.push(
                  <MoonView moon={moon} key={j} moonId={j} planetId={i} parentposition={position} position={moon_position} scale={[moon_size,moon_size,moon_size]} distance={moon.distance/(system.getAstronomicalUnit()/250)+size*1.5}
                  setSelected={setObjectSelected} objectSelected={objectSelected}/>
                )
              }
            }
            rows.push(
              <group key={i}>
                <PlanetView planet={planet} planetId={i} position={position} scale={[size,size,size]} distance={planet.distance/(system.getAstronomicalUnit()/100)}
                setSelected={setObjectSelected} objectSelected={objectSelected}/>
                {moon_rows}
              </group>
            );
        }else if(system.objects[i] instanceof Star){
            let size = system.objects[i].radius/SUN_DATA.radius * 10
            rows.push(
            <StarView star={system.objects[i]} key={i} starId={i} position={[0,0,0]} scale={[size,size,size]}
            color={getColorByHisTemp(system.objects[i].temperature)} emissive={getColorByHisTemp(system.objects[i].temperature)}
            setSelected={setObjectSelected} objectSelected={objectSelected}/>
            );
        }else if(system.objects[i] instanceof AsteroidBelt){
          let asteroidbelt = system.objects[i];
          const asteroid_rows = [];
          for(let j=0; j < asteroidbelt.objects.length; j++){
            if(asteroidbelt.objects[j] instanceof Asteroid){
              let asteroid = asteroidbelt.objects[j];
              let asteroid_size = 0.5;
              let position = new THREE.Vector3(
                asteroid.distance/(system.getAstronomicalUnit()/100) * Math.cos(asteroid.angle),
                asteroid.distance/(system.getAstronomicalUnit()/100) * Math.sin(asteroid.angle),
                0)
              asteroid_rows.push(
                <AsteroidView asteroid={asteroid} key={j} asteroidId={j} hostId={i} position={position} scale={[asteroid_size,asteroid_size,asteroid_size]}
                setSelected={setObjectSelected} objectSelected={objectSelected}/>
              )
            }
          }
          rows.push(
          <group key={i}>
            <AsteroidBeltV belt={asteroidbelt} dist_factor={1/(system.getAstronomicalUnit()/100)}/>
            {asteroid_rows}
          </group>
          );
        }
    }
    return <>{rows}</>
  }

  function MoonView(props){

    const body = useMemo(
      ()=>{return <BodyView body={props.moon} />},
      []
    )

    let sprite_texture = useTexture('../assets/circle_sprite.png')

    let objectIsSelected = (props.objectSelected[0] == props.planetId && props.objectSelected[1] == props.moonId)
    useEffect(()=>{
      objectIsSelected = (props.objectSelected[0] == props.planetId && props.objectSelected[1] == props.moonId)
    }, [props.objectSelected])

    const sprite_material = useRef()
    useFrame((state,delta,frame) => {
      if(objectIsSelected){
      sprite_material.current.rotation = (sprite_material.current.rotation - Math.PI/10 * delta) % (Math.PI*2)
      }
    })

    return (
      <>
      <group {...props} onClick={
        pointer => {
          props.setSelected(objectIsSelected ? -1 : props.planetId,objectIsSelected ? -1 : props.moonId)
        }
      }>
        {body}
      </group>
      {props.distance !== undefined && <Line points={getPointsOnACircle(props.distance,30,props.parentposition.getComponent(0),props.parentposition.getComponent(1))} color={"white"} lineWidth={0.3}/>}
      
      <sprite position={props.position}
        visible = {objectIsSelected} scale={props.scale ? [props.scale[0]*5,props.scale[1]*5,props.scale[2]*5] : [2,2,2]}>
        <spriteMaterial ref={sprite_material} map={sprite_texture} color={"white"} />
      </sprite>
      
      </>
    )
  }

  
  function PlanetView(props){

      const body = useMemo(
        ()=>{return <BodyView body={props.planet} />},
        []
      )

      let sprite_texture = useTexture('../assets/circle_sprite.png')

      let objectIsSelected = (props.objectSelected[0] == props.planetId && props.objectSelected[1] == -1)
      useEffect(()=>{
        objectIsSelected = (props.objectSelected[0] == props.planetId && props.objectSelected[1] == -1)
      }, [props.objectSelected])

      const sprite_material = useRef()
      useFrame((state,delta,frame) => {
        if(objectIsSelected){
        sprite_material.current.rotation = (sprite_material.current.rotation - Math.PI/10 * delta) % (Math.PI*2)
        }
      })
        
      return (
        <>
        <group {...props} onClick={
          pointer => {
            props.setSelected(objectIsSelected ? -1 : props.planetId)
          }
          
        }>
          {body}
        </group>
        {props.distance !== undefined && <Line points={getPointsOnACircle(props.distance,80)} color={"white"} lineWidth={0.3}/>}
        
        <sprite position={props.position}
          visible = {objectIsSelected} scale={props.scale ? [props.scale[0]*5,props.scale[1]*5,props.scale[2]*5] : [2,2,2]}>
          <spriteMaterial ref={sprite_material} map={sprite_texture} color={"white"} />
        </sprite>
        
        </>
      )
  
  }

  function StarView(props){

    let sprite_texture = useTexture('../assets/circle_sprite.png')

    const body = useMemo(
      ()=>{return <BodyView body={props.star} />},
      []
    )

      let objectIsSelected = (props.objectSelected[0] == props.starId && props.objectSelected[1] == -1)
      useEffect(()=>{
        objectIsSelected = (props.objectSelected[0] == props.starId && props.objectSelected[1] == -1)
      }, [props.objectSelected])

      const sprite_material = useRef()
      useFrame((state,delta,frame) => {
        if(objectIsSelected){
        sprite_material.current.rotation = (sprite_material.current.rotation - Math.PI/10 * delta) % (Math.PI*2)
        }
      })
    
    return (
        <>
        <group {...props} onClick={
          pointer => {
            props.setSelected(objectIsSelected ? -1 : props.starId)
          }}>
            {body}
        </group>

        <sprite position={props.position}
          visible = {objectIsSelected} scale={props.scale ? [props.scale[0]*5,props.scale[1]*5,props.scale[2]*5] : [2,2,2]}>
          <spriteMaterial ref={sprite_material} map={sprite_texture} color={"white"} />
        </sprite>
        </>
      )
  }

  function AsteroidBeltV(props){

    const belt = props.belt;

    return (
        <AsteroidBeltView belt={belt} dist_factor={props.dist_factor}/>
    )
  }

  function AsteroidView(props){

    const body = useMemo(
      ()=>{return <BodyView body={props.asteroid}/>},
      []
    )
      
    return (
      <group {...props}>
        {body}
      </group>
    )

  }



function SystemScenePreView(props){
    //Build scene
    let camera_m = new THREE.PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 0.15, props.system.objects[props.system.objects.length-1].distance*750 );
    camera_m.position.set(0, 0, props.system.getAstronomicalUnit()/100);
    camera_m.up.set(0, 0, 1);

    const { gl,scene, camera } = useThree();


    const set = useThree((state) => state.set)
    useEffect(() => {
        set({ camera: camera_m})
      }, [])

    const orbit = useRef();

    useEffect(() => {
      if(props.objectSelected[0] > -1){
        let radius = props.system.objects[props.objectSelected[0]].radius;
        let size = props.system.objects[props.objectSelected[0]] instanceof Planet ? radius/EARTH_DATA.radius : radius/SUN_DATA.radius * 10;
        orbit.current.target = props.getCameraPosition();
        orbit.current.minDistance = size * 5;
        orbit.current.maxDistance= size * 30;
        orbit.current.panSpeed = 0;
      }else{
        orbit.current.target = new THREE.Vector3(0,0,0);
        orbit.current.minDistance = 1;
        orbit.current.maxDistance=props.system.objects[props.system.objects.length-1].distance*1.1/(props.system.getAstronomicalUnit()/100)*2.25
        orbit.current.panSpeed = 1;
        orbit.current.reset();
      }
      orbit.current.update();
    }, [props.getCameraPosition()])

      //gl.autoClear = true
      //gl.clear();
      gl.capabilities.precision = "lowp";
      gl.antialias = true;
      gl.logarithmicDepthBuffer = true;
      gl.setPixelRatio(window.devicePixelRatio);
      gl.toneMapping = THREE.ACESFilmicToneMapping;
      gl.toneMappingExposure = 1.0;

      scene.background = new THREE.Color(0x000000);
    return(
      <>
        <OrbitControls ref={orbit} 
          enableDamping={true} dampingFactor={0.05} screenSpacePanning={false} minDistance={1}
          maxDistance={props.system.objects[props.system.objects.length-1].distance*1.1/(props.system.getAstronomicalUnit()/100)} maxPolarAngle={(Math.PI / 2) - (Math.PI / 360)}
        />
        {<SystemSkybox  planet={props.system.objects[0]} reference={props.system} 
        size={props.system.objects[props.system.objects.length-1].distance*500./(props.system.getAstronomicalUnit()/100)}
    sunColor={getColorByHisTemp(props.system.objects[0].temperature)} planetsCount={0}/>}
      </>
    )
}

export function BodyGui(props){
  const body = props.body;

  let position = body.distance != null && body.angle != null ? new THREE.Vector3(
    body.distance/(props.system.getAstronomicalUnit()/100) * Math.cos(body.angle),
    body.distance/(props.system.getAstronomicalUnit()/100) * Math.sin(body.angle),
    0) : new THREE.Vector3();

  const { gl,scene, camera } = useThree();

    const [time, setTime] = useState(0);

    useFrame((state)=>{
        const { clock } = state;
        setTime(clock.getElapsedTime());
    })

  return(
    <>
    { body != undefined && 
      <CanvasGUI key="Body" id="Body" type="CANVAS">
          <AutoWindow title="Body" id="Body" camera={camera} time={time} object_position={position} removeChild={props.removeChild} addChild={props.addChild}>
              <ComponentWidget title={"Body "}>
                  <button onClick={(e)=>{e.preventDefault(); props.setScene(body); }}>BODY SCENE</button>
              </ComponentWidget>
          </AutoWindow>
      </CanvasGUI>}
    </>
  )
}

export default class SystemScene extends Component {

    constructor(props){
        super(props);
        this.state = {
            system: props.system !== undefined ? props.system : null,
            objectSelected: [-1,-1],
        }
    }

    getObjectSelectedPosition = () => {
      return this.state.objectSelected[0] > -1 ? 
      (this.state.objectSelected[1] > -1 ? this.state.system.objects[this.state.objectSelected[0]].objects[this.state.objectSelected[1]].getPosition().clone().divideScalar(this.state.system.getAstronomicalUnit()/100)
         : this.state.system.objects[this.state.objectSelected[0]].getPosition().clone().divideScalar(this.state.system.getAstronomicalUnit()/100))
       : new THREE.Vector3(0,0,0)
    }
    
    setObjectSelected = (planet_id, moon_id=-1) => {
      this.setState({objectSelected: [planet_id,moon_id]})
    }

    // <pointLight position={[0,0,0]}/>

    render(){
      let body_selected = this.state.objectSelected[0] > -1 ? this.state.system.objects[this.state.objectSelected[0]] : undefined;
        return(
            <>
                <ambientLight intensity={0.1}/>
                <SystemScenePreView system={this.state.system} getCameraPosition={this.getObjectSelectedPosition} objectSelected={this.state.objectSelected}/>
                <SystemSelection system={this.state.system} setObjectSelected={this.setObjectSelected} objectSelected={this.state.objectSelected}/>
                {getObjects(this.state.system, this.setObjectSelected, this.state.objectSelected)}
                <pointLight position={[0,0,0]} color={getColorByHisTemp(this.state.system.objects[0].temperature)}/>
                {body_selected != undefined && <BodyGui system={this.state.system} body={body_selected} removeChild={this.props.removeChild} addChild={this.props.addChild} setScene={this.props.setScene}/>}
            </>
        )
    }

}
