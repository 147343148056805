import CanvasGUI from "../CanvasGUI";
import DraggableFrame from "../DraggableFrame";
import {useState} from 'react'

export default function ImageWidget(props){

    const [size, setSize] = useState(props.size ?? {width:window.innerWidth/6,height:window.innerHeight/6});

    return(
        <img src={props.src} {...size}
        onClick={()=>{
            if(props.addChild != null){
                props.addChild(
                    <CanvasGUI type={"CANVAS"} key={"HEIGHTMAP_VIEWER"} id={"HEIGHTMAP_VIEWER"}>
                        <DraggableFrame position={[2.*window.innerHeight/6,10+window.innerHeight/6]} removeChild={props.removeChild} title={"HEIGHTMAP_VIEWER"} id={"HEIGHTMAP_VIEWER"}>
                            <img src={props.src} {...{width:1.5*window.innerHeight/2,height:1.5*window.innerHeight/2}}/>
                        </DraggableFrame>
                    </CanvasGUI>
                )
            }
        }}/>
    )

}