import * as THREE from 'three'
import { extend } from '@react-three/fiber'
import { NoiseChunk, MoreNoiseChunk } from '../../../utils/ShaderChunks'

class AreaCreatorAssetsShader extends THREE.ShaderMaterial {
  constructor() {
    super({
      vertexShader: `
      uniform sampler2D positions;
      uniform float u_size;
      uniform vec3 u_seed;

      varying vec3 vNormal;
      varying vec3 vPosition;
      varying vec3 v_displacedBitangent;
      varying vec3 v_displacedTangent;

      vec3 orthogonal(vec3 v) {
          return normalize(abs(v.x) > abs(v.z) ? vec3(-v.y, v.x, 0.0)
          : vec3(0.0, -v.z, v.y));
      }

      vec3 recalcNormals(vec3 newPos) {
          float offset = 1./1000.;
          vec3 tangent = orthogonal(normal);
          vec3 bitangent = normalize(cross(normal, tangent));
          vec3 neighbour1 = vec3(uv.x,uv.y,position.z) + tangent * offset;
          vec3 neighbour2 = vec3(uv.x,uv.y,position.z) + bitangent * offset;
          vec3 displacedNeighbour1 = texture2D(positions, neighbour1.xy).xyz;
          vec3 displacedNeighbour2 = texture2D(positions, neighbour2.xy).xyz;
          v_displacedTangent = displacedNeighbour1 - newPos;
          v_displacedBitangent = displacedNeighbour2 - newPos;
          return normalize(cross(v_displacedTangent, v_displacedBitangent));
      }

    
      void main() {

        vec3 pos = texture2D(positions, uv.xy).xyz;

        vNormal = recalcNormals(pos);
        vPosition = pos;
    
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      }`,
      fragmentShader: `
      varying vec3 vPosition;
      varying vec3 vNormal;
      varying vec3 v_displacedBitangent;
      varying vec3 v_displacedTangent;

      uniform bool u_ocean;
      uniform float u_oceanFloor;

      uniform bool u_assetsOceanEffect;

      uniform float u_assetsNoiseIntensity;
      uniform float u_assetsNoiseFrequency;
      uniform float u_assetsNoisePow;

      ${NoiseChunk}
      ${MoreNoiseChunk}


      void main() {

        float slope_impact = dot(vNormal, vec3(0.,0.,1.));
        if(abs(slope_impact) < 0.85){slope_impact = 0.;}
        else{slope_impact = 1.;}

        float intensity = 1.;

        if(u_assetsNoiseIntensity > 0.){
          intensity = clamp(pow(noise_curl(vPosition*u_assetsNoiseFrequency).x,u_assetsNoisePow),0.,1.);
        }

        float ocean_impact = 0.;
        if(u_ocean){
          if(vPosition.z <= u_oceanFloor){
            intensity = 0.;
          }
          else if(u_assetsOceanEffect){
            ocean_impact = 0.3 * clamp(1./pow((vPosition.z-u_oceanFloor+1.),18.),0.,1.);
            if(ocean_impact < 0.1){
              ocean_impact = 0.;
            }
          }
        }
        intensity = u_assetsNoiseIntensity*.5*intensity + ocean_impact;

        intensity = intensity * slope_impact;
        if(intensity < 0.1){
          intensity = 0.;
        }

        gl_FragColor = vec4(vec3(abs(intensity),abs(intensity),abs(intensity)),1.);
      }`,
      uniforms: {
        positions: { value: null },
        u_size: {value: 1000},

        u_ocean:{value: false}, u_oceanFloor:{value: 0.}, 
        u_assetsOceanEffect:{value: true},

        u_assetsNoiseIntensity:{value: 0.}, u_assetsNoiseFrequency:{value:2.5}, u_assetsNoisePow:{value:4.},

      },
    })
  }
}

extend({ AreaCreatorAssetsShader })
