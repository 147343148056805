import * as THREE from 'three'
import { extend } from '@react-three/fiber'
import { NoiseChunk, MoreNoiseChunk } from '../../../../utils/ShaderChunks'

class GravGenerationShader extends THREE.ShaderMaterial {
  constructor() {

    super({
      vertexShader: `varying vec2 vUv;
      varying vec3 vPosition;
      
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        vPosition = position;
      }`,
      fragmentShader: `

      varying vec2 vUv;
      varying vec3 vPosition;

      ${NoiseChunk}
      ${MoreNoiseChunk}

      void main() {

        vec3 st = vec3(vUv.x,vUv.y,0.);

        float noise = fbm(st+vec3(1.), 5, 4., 1.6, 0.6);
        noise = 10.*pow(noise,5.);

        //float radius = distance(vUv,vec2(.5,.5));
        //noise = 1.;
        //float std = 0.03;
        //float mean = 0.3;
        //noise *= 1./sqrt(2.*3.141)/std*exp(-pow(radius-mean,2.)/(2.*pow(std,2.)));
        //if(radius > 0.4 || radius < 0.2 || noise < 0.5){
        //    noise = 0.5;
        //}

        vec2 speed = vUv-vec2(.5,.5);
        //speed = vec2(0.);

        gl_FragColor = vec4(vec2(0.),clamp(noise,0.,9999.)*2.2, 1.);
      }`,
      uniforms: {
      }
    })
  }
}

extend({ GravGenerationShader })
