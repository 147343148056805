import { Abstract } from "lamina/vanilla";

export default class BasePlanetShader extends Abstract {


    /**Seed of the object*/
    static u_seed = [0.,0.,0];

    static u_terrainGain = 0.5;
    static u_terrainLacunarity = 2.5;
    static u_terrainFrequency = 1.0;
    static u_terrainOctaves = 6;

    static u_oceanFloor = 0.;
    static u_oceanColor = [1.,1.,1.];
    static u_oceanDepth = false;

    static u_grass = false;
    static u_grassSlopeMax = 0.;
    static u_grassColor = [1.,1.,1.];
    static u_grassDepth = false;
    static u_grassIntensity = 1.;

    static u_polarArea = false;
    static u_polarColor = [1.,1.,1.];
    static u_polarLatitude = 0.5;
    static u_polarBorder = 0.0;
    static u_polarIntensity = 1.;

    /**CRATERS */
        /**How much craters (octaves of craters) the planet/moon has*/
        static u_cratersLevel = 0;
        /**Craters intensity */
        static u_craterIntensity = 0.2;
        static u_craterLacunarity = 1.4;
        static u_craterFrequency = 0.8;
    //-----------------------------------------

    /**CRACKS */
        static u_cracksIntensity = 0.0;
        static u_cracksFrequency = 4.;
        static u_cracksOctaves = 4;
        static u_cracksWidth = .94;
    //-----------------------------------------

    /**COLOR PALLET: Color(x)=A+Bcos(pi*x*C+D)*/
        static u_colorMat1A = [1.,1.,1.];
        static u_colorMat1B = [0.,0.,0.];
        static u_colorMat1C = [0.,0.,0.];
        static u_colorMat1D = [0.,0.,0.];
        static u_colorMat2A = [1.,1.,1.];
        static u_colorMat2B = [0.,0.,0.];
        static u_colorMat2C = [0.,0.,0.];
        static u_colorMat2D = [0.,0.,0.];
        /** If 0 then the color will be 1 else if 1 it will be 2. This is an interpolation factor.*/
        static u_colorMixFactor = 0.;

        /**x = x * this_factor*/
        static u_colorMat1Multiplier = 1.;
        /**x = x * this_factor*/
        static u_colorMat2Multiplier = 1.;
        /**color = color * this_factor*/
        static u_colorMat1Intensity = 1.;
        /**color = color * this_factor*/
        static u_colorMat2Intensity = 1.;
    //-------------------------------------------

    static fragmentShader = ` 
        varying vec3 v_position;
        varying vec3 v_neighbour1;

        uniform vec3 u_seed;

        uniform int u_terrainOctaves;
        uniform float u_terrainGain;
        uniform float u_terrainLacunarity;
        uniform float u_terrainFrequency;

        uniform bool u_grass;
        uniform float u_grassSlopeMax;
        uniform vec3 u_grassColor;
        uniform bool u_grassDepth;
        uniform float u_grassIntensity;

        uniform bool u_polarArea;
        uniform float u_polarLatitude;
        uniform vec3 u_polarColor;
        uniform float u_polarBorder;
        uniform float u_polarIntensity;

        uniform int u_cratersLevel;
        uniform float u_craterIntensity;
        uniform float u_craterLacunarity;
        uniform float u_craterFrequency;

        uniform float u_cracksIntensity;
        uniform float u_cracksFrequency;
        uniform int u_cracksOctaves;
        uniform float u_cracksWidth;

        uniform float u_oceanFloor;
        uniform vec3 u_oceanColor;
        uniform bool u_oceanDepth;

        //COLOR PALLET:
        uniform vec3 u_colorMat1A;
        uniform vec3  u_colorMat1B;
        uniform vec3  u_colorMat1C;
        uniform vec3  u_colorMat1D;
        uniform vec3  u_colorMat2A;
        uniform vec3  u_colorMat2B;
        uniform vec3  u_colorMat2C;
        uniform vec3  u_colorMat2D;
        uniform float u_colorMixFactor;

        uniform float u_colorMat1Multiplier;
        uniform float  u_colorMat2Multiplier;
        uniform float  u_colorMat1Intensity;
        uniform float  u_colorMat2Intensity;
    //-------------------------------------------

        vec2 bandpass(float value, float minimum, float maximum){
            if(value < minimum || value > maximum){return vec2(0.,0.);}
            return vec2(1.0,value);
        }

        float fbm(vec3 st, int octaves, float freq, float lacunarity, float gain) {
            float value = 0.0;
            float amplitude = 1.0;
            float frequency = freq;
            float max = 0.0;
            for (int i = 0; i < octaves; i++) {
              value += amplitude * lamina_noise_perlin((st)*frequency);
              frequency *= lacunarity;
              max += amplitude;
              amplitude *= gain;
            }
            return value/max;
        }

        float ridge_lines(vec3 st, float frequency,float min_value){
            float noise = fbm(st,3,frequency,2.,0.6);
            noise = -2.*abs(noise-.5)+1.;
            noise = pow(noise,1.);
            vec2 bp_noise = bandpass(noise, min_value, 1.);
            noise = bp_noise.y-min_value;
            return clamp(noise,0.,1.);
        }

        float ridge_fbm(vec3 st, int octaves, float freq, float lacunarity, float gain) {
            float value = 0.0;
            float amplitude = 1.0;
            float frequency = freq;
            float max = 0.0;
            for (int i = 0; i < octaves; i++) {
              value += amplitude * ridge_lines(st,frequency,u_cracksWidth);
              frequency *= lacunarity;
              max += amplitude;
              amplitude *= gain;
            }
            return value/max;
        }

        float craters(vec3 st, float frequency){

            float noise = lamina_noise_worley(st*frequency)-fbm(st,3,frequency*10.,1.5,0.5)*0.2;
            vec2 bp_noise = bandpass(noise, 0.0, 0.25);
            noise = bp_noise.y;
            if(bp_noise.x != 0.){
                noise = -noise +1.;
            }
            noise = pow(noise,2.);

            return clamp(noise,0.,1.);
        }

        float base_texture(vec3 st){

            float noise = fbm(st,u_terrainOctaves,u_terrainFrequency,u_terrainLacunarity,u_terrainGain);

            //Craters serie
            float craters_noise = 0.;
            for(int i=0;i<u_cratersLevel;i++){
                craters_noise += craters(st,u_craterFrequency*pow(u_craterLacunarity,float(i)));
            }
            craters_noise = -u_craterIntensity*craters_noise;

            if(u_cracksIntensity != 0.){
                noise = noise - u_cracksIntensity*ridge_fbm(st,u_cracksOctaves,u_cracksFrequency,1.5,0.65);
            }

            noise = noise+craters_noise;
            noise = pow(noise,2.0);

            return noise;
        }

        vec3 pal( float n, vec3 a, vec3 b, vec3 c, vec3 d )
        {
            return a+b*cos(6.28318*(c*n+d));
        }

        float displace(vec3 position){
            return base_texture(position+u_seed*0.1);
        }

        void main() {

            float intensity = displace(v_position);
            float intensity_neighbour = displace(v_neighbour1);
            float intensity_wth_shadow = clamp(intensity - 800.*clamp(intensity_neighbour - intensity,0.,1.),0.1,1.);

            vec3 colorMat1 = u_colorMat1Intensity*pal(intensity*u_colorMat1Multiplier,u_colorMat1A,u_colorMat1B,u_colorMat1C,u_colorMat1D);
            vec3 colorMat2 = u_colorMat2Intensity*pal(intensity*u_colorMat2Multiplier,u_colorMat2A,u_colorMat2B,u_colorMat2C,u_colorMat2D);
            vec3 baseFinalColor = mix(colorMat1,colorMat2,u_colorMixFactor);

            vec4 f_colorfrag = vec4(baseFinalColor*intensity_wth_shadow,1.);

            if(u_grass){
                if(atan(abs(intensity_neighbour-intensity)/0.0001) <= u_grassSlopeMax){
                    f_colorfrag = vec4(u_grassColor, 1.);
                    if(u_grassDepth){
                        f_colorfrag = vec4(u_grassColor*intensity*u_grassIntensity, 1.);
                    }
                }
            }
            

            if(intensity < u_oceanFloor){
                f_colorfrag = vec4(u_oceanColor,1.);
                if(u_oceanDepth){
                    f_colorfrag = vec4(u_oceanColor*max(0.01,(intensity/u_oceanFloor)),1.);
                }
            }

            if(u_polarArea){
                if(abs(dot(vec3(0.,0.,1.),v_position)) + intensity * u_polarBorder > u_polarLatitude){
                    f_colorfrag = vec4(u_polarColor*intensity*u_polarIntensity,1.);
                }
            }

            return f_colorfrag;
        }
    `;

    static vertexShader = `  
        varying vec3 v_position;
        varying vec3 v_neighbour1;
        
        vec3 orthogonal(vec3 v) {
            return normalize(abs(v.x) > abs(v.z) ? vec3(-v.y, v.x, 0.0)
            : vec3(0.0, -v.z, v.y));
        }

        void calcNeighbours(vec3 newPos) {
            float offset = 0.0001;
            vec3 tangent = orthogonal(vec3(0.,1.,1.));
            vec3 neighbour1 = position + tangent * offset;
            v_neighbour1 = neighbour1;
        }

        void main() {

            v_position = position;

            calcNeighbours(position);

            return position;
        }
    `;

    constructor(props) {
        super(BasePlanetShader, {
          name: "BasePlanetShader",
          ...props,
        });
      }
}