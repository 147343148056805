import {  useFBO, useGLTF, Instances, Instance} from '@react-three/drei'
import * as THREE from 'three'
import { createPortal, useFrame} from '@react-three/fiber'
import { useEffect, useRef, useMemo, useState } from 'react'

import '../../scenes/UtilsScene/AreaCreatorShaders/areaCreatorSimulationShader'
import '../../scenes/UtilsScene/AreaCreatorShaders/areaCreatorViewShader'
import '../../scenes/UtilsScene/AreaCreatorShaders/areaCreatorAssetsShader'

function AssetView(props){

    return(
            <Instance {...props} rotation={[Math.PI/2,Math.PI/8*Math.random()-Math.PI/16,Math.PI/8*Math.random()-Math.PI/16]}/>
    )
}

function AreaView(props){

    const geometry = props.geometry;
    const lowpoly_geometry = props.lowpoly_geometry;

    const translation = props.translation ?? new THREE.Vector3(0.,0.,0.);

    const size = (props.size ?? 1000 );

    const { nodes, materials } = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/tree-spruce/model.gltf')

    const simRef = useRef()
    const assetsRef = useRef();
    const renderRef = useRef()
    const renderMeshRef = useRef()
    const lowPolyMeshRef = useRef()
    const lowPolyRenderRef = useRef()

    const [hasHeightMap, setHasHeightmap] = useState(false);

    const [assets, setAssets] = useState([]);
    const addAsset = (position) => {
        setAssets((prevComponents) => [
          ...prevComponents,
          <AssetView key={prevComponents.length} position={position} scale={0.0007} />
        ]);
      };
    

    const [scene] = useState(() => new THREE.Scene())
    const [camera] = useState(() => new THREE.OrthographicCamera(-1, 1, 1, -1, 1 / Math.pow(2, 53), 1))
    const target = useFBO(size+1, size+1, {
      minFilter: THREE.NearestFilter,
      magFilter: THREE.NearestFilter,
      format: THREE.RGBAFormat,
      type: THREE.FloatType
    })
    const [scene_assets] = useState(() => new THREE.Scene())
    const target_assets = useFBO(size+1, size+1, {
      minFilter: THREE.NearestFilter,
      magFilter: THREE.NearestFilter,
      format: THREE.RGBAFormat,
      type: THREE.FloatType
    })

      //Generate heightmap
      useFrame((state) => {
        if(!hasHeightMap){
          
          simRef.current.uniforms.u_seed.value = translation.clone().multiplyScalar(.5);
          if(props.uniforms_simulation != null && Object.keys(props.uniforms_simulation).length > 0){
            simRef.current.uniforms.u_seed.value = translation.clone().multiplyScalar(.5).add(props.uniforms_simulation.u_seed.value);
            
            simRef.current.uniforms.u_terrainOctaves = props.uniforms_simulation.u_terrainOctaves;
            simRef.current.uniforms.u_terrainFrequency = props.uniforms_simulation.u_terrainFrequency;
            simRef.current.uniforms.u_terrainLacunarity = props.uniforms_simulation.u_terrainLacunarity;
            simRef.current.uniforms.u_terrainGain = props.uniforms_simulation.u_terrainGain;
            simRef.current.uniforms.u_terrainExp = props.uniforms_simulation.u_terrainExp;
            simRef.current.uniforms.u_terrainIntensity = props.uniforms_simulation.u_terrainIntensity;

            simRef.current.uniforms.u_ridgeOctaves = props.uniforms_simulation.u_ridgeOctaves;
            simRef.current.uniforms.u_ridgeIntensity = props.uniforms_simulation.u_ridgeIntensity;
            simRef.current.uniforms.u_ridgeFrequency = props.uniforms_simulation.u_ridgeFrequency;
            simRef.current.uniforms.u_ridgeLacunarity = props.uniforms_simulation.u_ridgeLacunarity;
            simRef.current.uniforms.u_ridgeGain = props.uniforms_simulation.u_ridgeGain;
            simRef.current.uniforms.u_ridgeWidth = props.uniforms_simulation.u_ridgeWidth;
            simRef.current.uniforms.u_ridgeSmoothstepMax = props.uniforms_simulation.u_ridgeSmoothstepMax;

            simRef.current.uniforms.u_cratersLevel = props.uniforms_simulation.u_cratersLevel;
            simRef.current.uniforms.u_cratersFrequency = props.uniforms_simulation.u_cratersFrequency;
            simRef.current.uniforms.u_cratersGain = props.uniforms_simulation.u_cratersGain;
            simRef.current.uniforms.u_cratersIntensity = props.uniforms_simulation.u_cratersIntensity;
            simRef.current.uniforms.u_cratersLacunarity = props.uniforms_simulation.u_cratersLacunarity;
            simRef.current.uniforms.u_cratersLimit = props.uniforms_simulation.u_cratersLimit;

            simRef.current.uniforms.u_globalFrequency = props.uniforms_simulation.u_globalFrequency;
          }
          
          state.gl.setRenderTarget(target)
          state.gl.clear()
          state.gl.render(scene, camera)
          state.gl.setRenderTarget(null)

          let pixelBuffer = new Float32Array((size+1)*(size+1) * 4);
          state.gl.readRenderTargetPixels(target, 0, 0, size+1, size+1, pixelBuffer);
          let noise_values = []
          //The noise value is the BLUE component in the simulation shader (Red & Green are for position and Alpha is not used)
          let maximum_noise = -999;
          let minimum_noise = 999;
          for(let i = 0; i < pixelBuffer.length; i += 4){
            noise_values.push(pixelBuffer[i+2]);
            maximum_noise = Math.max(maximum_noise,pixelBuffer[i+2]);
            minimum_noise = Math.min(minimum_noise,pixelBuffer[i+2]);
          }
          if(props.setTexture != undefined){
            const texture = new THREE.DataTexture( pixelBuffer, size+1, size+1 );
            texture.needsUpdate = true;
            const canvas = document.createElement('canvas');
            let width = size+1;
            let height = size+1;
            canvas.width = size+1;
            canvas.height = size+1;
            const context = canvas.getContext('2d');
            const imageData = new ImageData(width, height);
            const data = imageData.data;
            for (let i = 0; i < pixelBuffer.length; i++) {
              data[i] = (pixelBuffer[i-i%4+2]-minimum_noise)/(maximum_noise-minimum_noise)*255;
              if(i%4==3){data[i]=255}
            }
            context.putImageData(imageData, 0, 0);
        
            // Get the data URL from the canvas
            const dataUrl = canvas.toDataURL();
            props.setTexture(dataUrl)
          }
          
          //auto-adjust the z-position of the mesh
          renderMeshRef.current.position.z = -minimum_noise;
          lowPolyMeshRef.current.position.z = -minimum_noise;

          //Spawn asset
          assetsRef.current.uniforms["positions"] = {value:target.texture}
          if(props.uniforms_view != null && Object.keys(props.uniforms_view).length > 0){
            assetsRef.current.uniforms.u_ocean = props.uniforms_view.u_ocean;
            assetsRef.current.uniforms.u_oceanFloor = props.uniforms_view.u_oceanFloor;
          }
          if(props.uniforms_assets != null && Object.keys(props.uniforms_assets).length > 0){
            assetsRef.current.uniforms.u_assetsOceanEffect = props.uniforms_assets.u_assetsOceanEffect;
            assetsRef.current.uniforms.u_assetsNoiseIntensity = props.uniforms_assets.u_assetsNoiseIntensity;
            assetsRef.current.uniforms.u_assetsNoiseFrequency = props.uniforms_assets.u_assetsNoiseFrequency;
            assetsRef.current.uniforms.u_assetsNoisePow = props.uniforms_assets.u_assetsNoisePow;
          }
          state.gl.setRenderTarget(target_assets)
          state.gl.clear()
          state.gl.render(scene_assets, camera)
          state.gl.setRenderTarget(null)
          let assetsBuffer = new Float32Array((size+1)*(size+1) * 4);
          state.gl.readRenderTargetPixels(target_assets, 0, 0, size+1, size+1, assetsBuffer);
          let assets_intensity = []
          //The intensity value is in blue channel
          let maximum_assets_intensity = -999;
          let minimum_assets_intensity = 999;
          for(let i = 0; i < pixelBuffer.length; i += 4){
            assets_intensity.push(assetsBuffer[i+2]);
            maximum_assets_intensity = Math.max(maximum_assets_intensity,assetsBuffer[i+2]);
            minimum_assets_intensity = Math.min(minimum_assets_intensity,assetsBuffer[i+2]);
          }

          const spawning_threshold = 0.97;
          setAssets([])
          if(props.uniforms_assets?.u_assets?.value){
          for(let i = 0; i < noise_values.length; i++){
            if(Math.random()>=spawning_threshold && Math.random()<=((assets_intensity[i]))){
                addAsset(new THREE.Vector3(
                    (i%(size+1)/(size+1))*2-1
                    ,(Math.floor(i / (size+1))/(size+1))*2-1
                    ,noise_values[i]-minimum_noise));
            }
          }
          }

          setHasHeightmap(true)

          renderRef.current.uniforms["positions"] = {value:target.texture}
          renderRef.current.uniforms["assets"] = {value:target_assets.texture}
          renderRef.current.uniforms["u_size"] = {value:size};
          renderRef.current.uniforms["u_seed"] = {value:translation.clone()};
          lowPolyRenderRef.current.uniforms.positions.value = target.texture
          }
      })

      useEffect(()=>{
        setHasHeightmap(false)
      }, [props.uniforms_simulation])
      
      useEffect(()=>{
        if(props.uniforms_view != null && Object.keys(props.uniforms_view).length > 0){
          

          renderRef.current.uniforms.u_grassColor.value = 
          new THREE.Vector4(props.uniforms_view.u_grassColor.value.r/255,props.uniforms_view.u_grassColor.value.g/255,props.uniforms_view.u_grassColor.value.b/255
            ,props.uniforms_view.u_grassColor.value.a);
          renderRef.current.uniforms.u_edgeColor.value = 
          new THREE.Vector4(props.uniforms_view.u_edgeColor.value.r/255,props.uniforms_view.u_edgeColor.value.g/255,props.uniforms_view.u_edgeColor.value.b/255
            ,props.uniforms_view.u_edgeColor.value.a);
          renderRef.current.uniforms.u_plainColor.value = 
          new THREE.Vector4(props.uniforms_view.u_plainColor.value.r/255,props.uniforms_view.u_plainColor.value.g/255,props.uniforms_view.u_plainColor.value.b/255
              ,props.uniforms_view.u_plainColor.value.a);

          renderRef.current.uniforms.u_grass = props.uniforms_view.u_grass;
          renderRef.current.uniforms.u_grassSlopeMax = props.uniforms_view.u_grassSlopeMax;
          
          renderRef.current.uniforms.u_ocean = props.uniforms_view.u_ocean;
          renderRef.current.uniforms.u_oceanColor.value = 
          new THREE.Vector4(props.uniforms_view.u_oceanColor.value.r/255,props.uniforms_view.u_oceanColor.value.g/255,props.uniforms_view.u_oceanColor.value.b/255
            ,props.uniforms_view.u_oceanColor.value.a);
          renderRef.current.uniforms.u_oceanFloor = props.uniforms_view.u_oceanFloor;
        }
      }, [props.uniforms_view])

      //Render plane is the high detailled mesh 
      const renderPlane = useMemo(()=>{
        return(
          <mesh ref={renderMeshRef} scale={[1.,1.,1.]}>
            <areaCreatorViewShader flatShading={true} ref={renderRef}/>
            <bufferGeometry {...geometry}>
            </bufferGeometry>
          </mesh>
        )
      }, [hasHeightMap,props.uniforms_view])

      //Low poly is instead used as a lower quality mesh in order to perform raytracing and dynamic events
      const lowPolyRenderPlane = useMemo(()=>{
        return(
          <mesh ref={lowPolyMeshRef} scale={[1.,1.,1.]}>
            <areaCreatorViewShader flatShading={true} ref={lowPolyRenderRef}/>
            <bufferGeometry {...lowpoly_geometry}>
            </bufferGeometry>
          </mesh>
        )
      }, [hasHeightMap])


    return(
        <>
        {!hasHeightMap && createPortal(
            <mesh >
              <areaCreatorSimulationShader ref={simRef} />
              <bufferGeometry {...lowpoly_geometry}>
              </bufferGeometry>
            </mesh>,
            scene
          )
        }
        {!hasHeightMap && createPortal(
            <mesh>
              <areaCreatorAssetsShader ref={assetsRef} />
              <bufferGeometry {...geometry}>
              </bufferGeometry>
            </mesh>,
            scene_assets
          )
        }
        <group position={translation.clone().multiplyScalar(0.99)}>
          <Instances limit={10000} range={assets.length} frustumCulled={false} geometry={nodes["tree-spruce"].geometry} material={materials["color_main"]}>
            {assets}
          </Instances>
          {renderPlane}
          <group visible={false}>
            {lowPolyRenderPlane}
          </group>
        </group>
        </>
    )
}

function createAreaArray(number,props,uniforms_global,uniforms_view, uniforms_simulation){
  const areas = [];
  const size = 2.;
  const dist = 0.75//2.5;
  if(uniforms_global?.oneTile){
    areas.push(
      <AreaView {...props} key={1} uniforms_view={uniforms_view} uniforms_simulation={uniforms_simulation}/>
    )
    return <>{areas}</>;
  }
  for (let i = -number*dist; i < number*dist; i++) {
    for (let j = -number*dist; j < number*dist; j++) {
      let p3 = {...props};
      if(Math.sqrt(i*i+j*j) > number*2.){
        p3["geometry"] = props.geometry_lod2;
        p3["size"] = 50;
      }else if(Math.sqrt(i*i+j*j) > number*1.1){
        p3["geometry"] = props.geometry_lod1;
        p3["size"] = 250;
      }
      areas.push(
        <AreaView key={number*dist*2*i+j} {...p3} uniforms_view={uniforms_view} uniforms_simulation={uniforms_simulation} translation={new THREE.Vector3(i*size,j*size,0)}/>
      )
    }
  }
  return <>{areas}</>;
}

export function ListAreaView(props){

  const geometry = useMemo(()=>new THREE.PlaneGeometry(2,2,1000,1000));
  const geometry_lod1 = useMemo(()=>new THREE.PlaneGeometry(2,2,250,250));
  const geometry_lod2 = useMemo(()=>new THREE.PlaneGeometry(2,2,50,50));
  const lowpoly_geometry = useMemo(()=>new THREE.PlaneGeometry(2,2,50,50));

  const p2 = {...props};

  p2["geometry"] = geometry;
  p2["geometry_lod1"] = geometry_lod1;
  p2["geometry_lod2"] = geometry_lod2;
  p2["lowpoly_geometry"] = lowpoly_geometry;

  return(
    <>
    {createAreaArray(3,p2,props.uniforms_global,props.uniforms_view,props.uniforms_simulation)}
    </>
  )

}