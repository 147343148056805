export const UI_COLOR = {
    opaque_background:"rgba(29, 32, 33, 1)",
    background:"rgba(29, 32, 33, 0.5)",
    border: "rgba(199,156,107,1)",
    title:"rgba(255,255,255,1)",
    close:"rgba(255, 255, 255,0.0)",
    hover:"rgba(255, 255, 255, 0.7)"
}

export const UI_BORDER = "2px solid "+UI_COLOR.border;

export const UI_STYLES = {
    title: {fontWeight: "bold", fontSize:"1em", padding:"0em 0.5em", color:UI_COLOR.title}
}

//export const UI_SYSTEM_SCENE_SCALE = 

//Percent of screen size
export const UI_SYSTEM_SELECT_SIZE = [.125,.25]
export const UI_SYSTEM_GO_SIZE = [.075,0]
export const UI_LOCATION_BAR_PROPS = {
    size:[0,"2em"]
}
