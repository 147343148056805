export class Ring {

    constructor(planet, data){

        this.parent = planet;

        this.radius = data["radius"];

        let serie_data = data["serie"].split(';');
        this.serie = {
            frequency: serie_data[0],
            lacunarity: serie_data[1],
            gain: serie_data[2],
            intensity: serie_data[3],
            octaves: serie_data[4],
        }

    }

}