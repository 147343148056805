export class Atmosphere {

    constructor(planet, data){

            this.parent = planet;

            this.type = data["type"] ?? "VERY_THIN";
            this.pressure = data["pressure"] ?? 0.1;

    }

    getAtmosphereDensity(){
        return (this.pressure*(.4/2.))+.6;
    }

    getCratersLevel(){
        switch(this.type){
            case "TRACE": return 5;
            case "VERY_THIN": return 5;
            case "THIN": return 3;
            case "STANDARD": return 1;
            case "DENSE": return 0;
            case "VERY_DENSE": return 0;
            case "SUPERDENSE": return 0;
            default: return 1;
        }

    }

}