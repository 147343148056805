import { Abstract } from "lamina/vanilla";

export default class StarShader extends Abstract {

    static u_seed = [1.,1.,1.];
    static u_time = 0.;
    static u_speed = 0.2;
    static u_color = [1.,1.,1.];

    static fragmentShader = ` 
        varying vec3 v_position;
        uniform vec3 u_seed;
        uniform vec3 u_color;
        uniform float u_speed;

        uniform float u_time;

        float fbm(vec3 st, int octaves, float freq, float lacunarity, float gain) {
            float value = 0.0;
            float amplitude = 1.0;
            float frequency = freq;
            float maximum = 0.0;
            for (int i = 0; i < octaves; i++) {
              value += amplitude * lamina_noise_simplex((st+u_seed)*frequency);
              frequency *= lacunarity;
              maximum += amplitude;
              amplitude *= gain;
            }
            return pow(value/maximum,2.5)*2.;
        }

        vec3 brightnessToColor(float b,float d, vec3 color){
            b *= 1.0;
            return b*color*d;
        }

        vec3 sunLayer(vec3 position){
            
            vec3 color = u_color;
            float frequency = 4.;

            float noise1 = fbm(position+vec3(sin(u_time*u_speed)),5,frequency,2.5,0.6);
            vec3 layer1 = brightnessToColor(noise1,0.3,color);

            float noise2 = fbm(position+vec3(0.,sin(u_time*u_speed+3.141*.25),0.),5,frequency,2.5,0.6);
            vec3 layer2 = brightnessToColor(noise2,0.7,color);

            float noise3 = fbm(position+vec3(sin(u_time*u_speed+3.141*.5),0.,0.),5,frequency,2.5,0.6);
            vec3 layer3 = brightnessToColor(noise3,1.0,color);

            vec3 f_value = layer1+layer2+layer3;
            return f_value;
        }

        void main() {


            vec4 f_colorfrag = vec4(sunLayer(v_position+u_seed),1.); 

            return f_colorfrag;
        }
    `;

    static vertexShader = `  
        varying vec3 v_position;
        
        void main() {

            v_position = position;
            return position;
        }
    `;

    constructor(props) {
        super(StarShader, {
          name: "StarShader",
          ...props,
        });
      }
}