import { useFrame, extend } from "@react-three/fiber";
import { Fresnel, LayerMaterial } from "lamina";
import {  useRef } from "react";

import BasePlanetShader from "../shaders/BasePlanetShader";
import AtmosphereShader from "../shaders/AtmosphereShader";
import AtmosphereMaterial from "../../../materials/AtmosphereMaterial"
extend({ BasePlanetShader, AtmosphereShader})

export default function MoonView(props){
  const materialRef = useRef()
  const atmomaterialRef = useRef()

  const meshRef = useRef()

  const atmomeshRef = useRef()

  useFrame((state) => {
    const { clock } = state;
    materialRef.current.time = clock.getElapsedTime();
    atmomaterialRef.current.time = clock.getElapsedTime();
    meshRef.current.rotation.z = clock.getElapsedTime()*.0;
  });

  return (
    <>
    {<mesh transparent ref={atmomeshRef} {...props} rotation={[0, 0, 0]}>
      <icosahedronGeometry args={[1.2, 60]} />
      <AtmosphereMaterial color={[0.3,0.3,0.3]} pressure={props.body.atmosphere.pressure}/>
    </mesh>}
    <mesh transparent ref={meshRef} {...props} rotation={[0, 0, 0]}>
      <icosahedronGeometry args={[1,20]} />
      <LayerMaterial lighting="lambert" fog={false} flatShading={false}>
        <basePlanetShader ref={materialRef} time={0.0} cratersLevel={props.body.atmosphere.getCratersLevel()} terrainOctaves={6} seed={props.body?.seed?.getValue()??[0.,0.,0.]}/>
        {<atmosphereShader ref={atmomaterialRef} density={props.body.atmosphere.getAtmosphereDensity()} color={[.45,.45,.45]} mode="add"/>}
        <Fresnel intensity={1.4} color={[0.3,0.3,0.3]} mode="add" />
      </LayerMaterial>
    </mesh>
    </>
  )
}
