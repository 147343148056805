
export const vertexShader = `
  varying vec3 vNormal;
  varying vec3 vPosition;

  void main() {
    vNormal = normalize( normalMatrix * normal );

    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    vPosition = gl_Position.xyz;
  }
`

export const fragmentShader = `
    varying vec3 vNormal;
    varying vec3 vPosition;

    uniform vec3 u_color;
    uniform float u_pressure;

    void main() {
        float intensity = pow(clamp(min(0.7*u_pressure,0.7) - dot( vNormal, vec3( 0, 0, 1.0 ) ),0.,1.), 8.0 );
        gl_FragColor = vec4( u_color, 1.0 ) * intensity * min(u_pressure,1.);
    }
`   