import * as THREE from 'three'
import { Seed } from './utils/Seed.js';
import { Atmosphere } from './Atmosphere.js';

export class Moon {

    constructor(planet, data){

        this.parent = planet;

        this.mass = data["mass"];
        this.distance = data["distance"];
        this.angle = data["angle"];
        this.radius = data["radius"];
        this.seed = new Seed(data["seed"]);

        if(data["atmosphere"] != null){
        this.atmosphere = new Atmosphere(this,data["atmosphere"]);
        }
        else {this.atmosphere = planet.atmosphere}
    }
    
    getPosition(origin="SYSTEM"){
        let local_pos = new THREE.Vector3(
            this.distance * Math.cos(this.angle),
            this.distance * Math.sin(this.angle),
            0);
        switch(origin.toUpperCase()){
            case "SYSTEM": return local_pos.add(this.parent.getPosition());
            case "PLANET": return local_pos
            default: return local_pos
        }
    }

}