import { OrbitControls} from '@react-three/drei'
import * as THREE from 'three'
import { useFrame, useThree} from '@react-three/fiber'
import { useEffect ,useRef, Component, useMemo, useState } from 'react'

import { Instances, Instance, Line} from '@react-three/drei'
import { getColorByHisTemp } from '../../utils'

import { extend } from "@react-three/fiber";
import { LayerMaterial} from "lamina";

import AutoWindow from '../utils/AutoWindow'
import CanvasGUI from '../utils/CanvasGUI'
import ComponentWidget from '../utils/widgets/ComponentWidget'

import GalaxyShader from './GalaxyScene/shaders/galaxy/GalaxyShader'
extend({ GalaxyShader });

//List of JSX System components 
function getSystems(galaxy, setSystemSelected, systemSelected){
  
  const systems = galaxy.systems.getSystems();
  const rows = [];
  for (let i = 0; i < systems.length; i++) {
      rows.push(
      <SystemView system={systems[i]} key={i} systemId={i} position={systems[i].position} scale={0.0025}
       color={systems[i].host.debugcolor} emissive={getColorByHisTemp(systems[i].objects[0].temperature)}
       setSelected={setSystemSelected} systemSelected={systemSelected}/>
      );
  }
  return( 
  <Instances limit={systems.length}>
    <sphereGeometry/>
    <meshStandardMaterial/>
    {rows}
  </Instances>
  )
}

//How a system will be rendered in the galactic view
function SystemView(props){

  let systemIsSelected = (props.systemSelected == props.systemId)
    useEffect(()=>{
      systemIsSelected = (props.systemSelected == props.systemId)
    }, [props.systemSelected])

    let scale = (props.scale ?? 0.1);
    if(systemIsSelected) scale *= 1.5;
    
  return (
    <>
    {<Instance {...props} scale={scale}
    onClick={
      pointer => {
        props.setSelected(systemIsSelected ? -1 : props.systemId)
      }
    }/>}
    </>
  )

}

function GalaxyView(props){
  const materialRef = useRef();
  const meshRef = useRef()

  const offset = new THREE.Vector3(0.,0.,0.);
  const { camera } = useThree();

  useFrame((state) => {
    const { clock } = state;
    materialRef.current.time = clock.getElapsedTime();
    materialRef.current.camPos = camera.position;
    meshRef.current.position.set(camera.position.getComponent(0),camera.position.getComponent(1),camera.position.getComponent(2));
  });

  const shader = useMemo(
    ()=>{return (
      <LayerMaterial lighting='basic' side={THREE.BackSide}>
        <galaxyShader ref={materialRef} deformationOffset={offset} time={0.0} camPos={[0.0,0.0,0.0]}/>
      </LayerMaterial>
    )},
    []
  )

  return (
    <>
    <mesh ref={meshRef} {...props} scale={5.} position={camera.position} >
      <icosahedronGeometry args={[1.0, 10.]}/>
      {shader}
    </mesh>
    </>
  )
}

function getHyperlines(system){
  
  if(system == undefined)return;

  const neighbors = system.getNeighbors(0.05,0.);
  const rows = [];
  for (let i = 0; i < neighbors.length; i++) {
      rows.push(
      <HyperlineView systemEnd={neighbors[i]} systemBegin={system} key={i}/>
      );
  }
  return rows;
}

function HyperlineView(props){
  return(
    <Line points={[props.systemBegin.position,props.systemEnd.position]} color={"white"} lineWidth={2.0} />
  )
}


function GalaxyScenePreView(props){
    //Build scene
    let camera_m = new THREE.PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 0.0001, 5000 );
    camera_m.position.set(0, 0, 7.5);
    camera_m.up.set(0, 0, 1);

    const orbit = useRef();

    const { gl,scene, camera } = useThree();

    const set = useThree((state) => state.set)
    useEffect(() => {
        set({ camera: camera_m})
      }, [])

      useEffect(() => {
        if(props.getCameraPosition().x != 0 && props.getCameraPosition().y != 0 && props.getCameraPosition().z != 0){
          orbit.current.target = props.getCameraPosition();
          orbit.current.minDistance = 0.01;
          orbit.current.maxDistance = 0.15;
          orbit.current.panSpeed = 0;
        }else{
          orbit.current.minDistance = 0.1;
          orbit.current.maxDistance = 10;
          orbit.current.panSpeed = 0.5;
        }
        orbit.current.update();
      }, [props.getCameraPosition()])

      //gl.autoClear = true
      //gl.clear();
      gl.antialias = true;
      gl.logarithmicDepthBuffer = true;
      gl.setPixelRatio(window.devicePixelRatio);
      gl.toneMapping = THREE.ACESFilmicToneMapping;
      gl.toneMappingExposure = 0.5;

      scene.background = new THREE.Color(0x000000);
    
    return(
      <>
        <OrbitControls ref={orbit}
          enableDamping={true} dampingFactor={0.05} screenSpacePanning={true} minDistance={0.1}
          maxDistance={10} panSpeed={0.5}
        />
      </>
    )
}

function SystemGui(props){
  const system = props.system;

  const { gl,scene, camera } = useThree();

    const [time, setTime] = useState(0);

    useFrame((state)=>{
        const { clock } = state;
        setTime(clock.getElapsedTime());
    })

  return(
    <>
    { system != undefined && 
      <CanvasGUI key="System" id="System" type="CANVAS">
          <AutoWindow title="System" id="System" camera={camera} time={time} object_position={system.position} removeChild={props.removeChild} addChild={props.addChild}>
              <ComponentWidget title={"System "+system.name}>
                  <button onClick={(e)=>{e.preventDefault(); props.setScene(system); }}>SYSTEM SCENE</button>
              </ComponentWidget>
          </AutoWindow>
      </CanvasGUI>}
    </>
  )
}

export default class GalaxyScene extends Component {

    constructor(props){
        super(props);
        this.state = {systemSelected : -1, galaxy:props.galaxy !== undefined ? props.galaxy : null};
    }

    getSystemSelectedPosition = () => {
      return this.state.systemSelected < 0 ? new THREE.Vector3(0,0,0) : this.state.galaxy.systems.getSystems()[this.state.systemSelected].position
    }
    
    setSystemSelected = (id) => {
      this.setState({systemSelected: id})
    }

    render(){
      let system_selected = this.state.systemSelected > -1 ? this.state.galaxy.systems.getSystems()[this.state.systemSelected] : undefined;
        return(
            <>
                {getSystems(this.state.galaxy, this.setSystemSelected, this.state.systemSelected)}
                {getHyperlines(system_selected)}
                <ambientLight intensity={1}/>
                <GalaxyScenePreView getCameraPosition={this.getSystemSelectedPosition}/>
                <GalaxyView/>
                <SystemGui system={system_selected} removeChild={this.props.removeChild} addChild={this.props.addChild} setScene={this.props.setScene}/>
            </>
        )
    }

}