import { useState, useEffect} from 'react'
import { UI_COLOR, UI_STYLES, UI_BORDER} from '../../config/uiSettings'
import IMG_MOVE_GUI from '../../assets/global/move.png'
import IMG_CLOSE_GUI from '../../assets/global/close.png'


//Props : 
// - position : {[number,number]} ;
// - title : {Component} ;
export default function DraggableFrame(props){

    const [size, setSize] = useState(props.size ?? {width:window.innerWidth,height:window.innerHeight});
    const [position, setPosition] = useState(props.position ? props.position : [size.width/2,size.height/2]);
    const [fullscreen, setFullcreen] = useState(false);

    useEffect(() => {
        function handleResize() {
            setSize(props.size ?? {width:window.innerWidth,height:window.innerHeight});
        }
        window.addEventListener('resize', handleResize);
    }, [])

    return(
            <div onPointerDown={event => {event.stopPropagation()}}
             style={{position:"absolute", overflow: "hidden", color:"white" ,width:fullscreen ? size.width : undefined, height:fullscreen ? size.height : undefined,
              top: fullscreen ? 0 : position[1], left: fullscreen ? 0 : position[0]}}>
                <div style={{backgroundColor:UI_COLOR.opaque_background, overflow: "hidden", userSelect:"none", borderBottom:UI_BORDER, width:"100%", height:"1.5em"}} 
                    onClick={(event)=>{if(event.detail == 2){
                        setFullcreen(!fullscreen);
                    }}}>
                    <div style={{float: "left", userSelect:"none", cursor: "move", height:"100%"}}
                        onDrag={event => {
                            let newPosX = event.clientX >= size.width ? size.width-10 : event.clientX;
                            if(newPosX < 0){newPosX = 5;}
                            let newPosY = event.clientY >= size.height ? size.height-10 : event.clientY;
                            if(newPosY < 0){newPosY = 5;}
                            setPosition([newPosX,newPosY])
                        }}
                        onDragEnd={event => {
                            let newPosX = event.clientX >= size.width ? size.width-10 : event.clientX;
                            if(newPosX < 0){newPosX = 5;}
                            let newPosY = event.clientY >= size.height ? size.height-10 : event.clientY;
                            if(newPosY < 0){newPosY = 5;}
                        setPosition([newPosX,newPosY])}}>
                        <img src={IMG_MOVE_GUI} width={"100%"} height={"100%"}/>
                    </div>
                    <div style={Object.assign({},UI_STYLES.title,{float: "left"})}>
                        {props.title ?? ""}
                    </div>
                    <div style={{backgroundColor:UI_COLOR.close, height:"100%", float: "right"}} 
                    onClick={()=>{if(props.id != undefined && props.removeChild != undefined){props.removeChild(props.id)}}}>
                        <img draggable={false} src={IMG_CLOSE_GUI} width={"100%"} height={"100%"}/>
                    </div>
                </div>
                <div style={{backgroundColor:UI_COLOR.background, overflow: "auto", resize:"both", maxHeight:size.height, height:fullscreen ? size.height : undefined}}>
                    {props.children}
                </div>
            </div>
    );
}