import {  useFBO,CameraControls, Grid, OrthographicCamera, MapControls} from '@react-three/drei'
import * as THREE from 'three'
import { createPortal, useFrame, useThree} from '@react-three/fiber'
import { useEffect, useRef, useMemo, useState, Component } from 'react'

import './gravSimulationShader'
import './gravViewShader'
import './gravGenerationShader'

function SceneView(props){

    const geometry = useMemo(()=>new THREE.PlaneGeometry(2,2,10,10));;
    const translation = props.translation ?? new THREE.Vector3(0.,0.,0.);

    const size = (props.size ?? 150 );

    const simRef = useRef()
    const generationRef = useRef()
    const renderRef = useRef()
    const renderMeshRef = useRef()

    const [hasHeightMap, setHasHeightmap] = useState(false);
    const [goCompute, setToCompute] = useState(false)
    

    const [scene_generation] = useState(() => new THREE.Scene())
    const generation_target = useFBO(size+1, size+1, {
        minFilter: THREE.NearestFilter,
        magFilter: THREE.NearestFilter,
        format: THREE.RGBAFormat,
        type: THREE.FloatType
      })

    const [scene] = useState(() => new THREE.Scene())
    const [camera] = useState(() => new THREE.OrthographicCamera(-1, 1, 1, -1, 1 / Math.pow(2, 53), 1))
    const target = useFBO(size+1, size+1, {
      minFilter: THREE.NearestFilter,
      magFilter: THREE.NearestFilter,
      format: THREE.RGBAFormat,
      type: THREE.FloatType
    })

      //Generate heightmap
      useFrame((state) => {
        if(!hasHeightMap){

          state.gl.setRenderTarget(generation_target)
          state.gl.clear()
          state.gl.render(scene_generation, camera)
          state.gl.setRenderTarget(null)
          
          simRef.current.uniforms.data.value = generation_target.texture;
          
          state.gl.setRenderTarget(target)
          state.gl.clear()
          state.gl.render(scene, camera)
          state.gl.setRenderTarget(null)

          setHasHeightmap(true)


          renderRef.current.uniforms["data"] = {value:target.texture}
          renderRef.current.uniforms["u_size"] = {value:size};
          }
        if(goCompute && simRef != null){

            let pixelBuffer = new Float32Array((size+1)*(size+1) * 4);
            state.gl.readRenderTargetPixels(target, 0, 0, size+1, size+1, pixelBuffer);
            const texture = new THREE.DataTexture( pixelBuffer, size+1, size+1, THREE.RGBAFormat, THREE.FloatType );
            texture.needsUpdate = true;

            simRef.current.uniforms.data.value = texture;
    
            state.gl.setRenderTarget(target)
            state.gl.clear()
            state.gl.render(scene, camera)
            state.gl.setRenderTarget(null)

            setToCompute(false);

          renderRef.current.uniforms["data"] = {value:target.texture}
        }
      })

      useEffect(() => {
        const intervalId = setInterval(() => {
            setToCompute(true)
        }, 10);
    
        return () => clearInterval(intervalId);
      }, []);

      const renderPlane = useMemo(()=>{
        return(
          <mesh ref={renderMeshRef} scale={[1.,1.,1.]}>
            <gravViewShader flatShading={false} ref={renderRef}/>
            <bufferGeometry {...geometry}>
            </bufferGeometry>
          </mesh>
        )
      })

    return(
        <>
        {!hasHeightMap && createPortal(
            <mesh >
              <gravGenerationShader ref={generationRef} />
              <bufferGeometry {...geometry}>
              </bufferGeometry>
            </mesh>,
            scene_generation
          )
        }
        {(!hasHeightMap || goCompute) && createPortal(
            <mesh >
              <gravSimulationShader ref={simRef} />
              <bufferGeometry {...geometry}>
              </bufferGeometry>
            </mesh>,
            scene
          )
        }
        <group position={translation.clone().multiplyScalar(0.99)}>
          {renderPlane}
        </group>
        </>
    )
}

export default class GravSimSceneGPU extends Component {

    constructor(props){
        super(props);
        this.state = {
            texture: null,
        }
    }

    shouldComponentUpdate(nextProps, nextState){
      if(nextState.texture !== this.state.texture){return true;}
      else{return false;}
    }
    
    getTexture = () => {
      return this.state.texture;
    }

    setTexture = (texture) => {
      this.setState({texture: texture})
    }

    render(){
        return(
            <>
                {/**<Grid infiniteGrid cellSize={0.1} rotation={[Math.PI/2,0,0]} fadeDistance={20} sectionColor={"black"}/>*/}
                <ambientLight intensity={0.2}/>
                <GravScenePreview/>
                <SceneView /**setTexture={this.setTexture} getTexture={this.getTexture}*//>
                <pointLight position={[5000,5000,5000]} color={"white"}/>
            </>
        )
    }
}

function GravScenePreview(props){
    //Build scene
    const aspect = window.innerWidth / window.innerHeight;
    let camera_m = new THREE.PerspectiveCamera( 60,aspect, 0.015, 5000000 );
    camera_m.up.set(0, 0, 1);
    camera_m.position.set(0, 0, 2);
    camera_m.lookAt(0,0,0);
  
    const { gl,scene } = useThree();
  
    const set = useThree((state) => state.set)
    useEffect(() => {
        set({ camera: camera_m})
      }, [])
  
      gl.antialias = true;
      gl.logarithmicDepthBuffer = true;
      gl.setPixelRatio(window.devicePixelRatio);
      gl.toneMapping = THREE.ACESFilmicToneMapping;
      gl.toneMappingExposure = 1.;
      gl.preserveDrawingBuffer = true;
  
      scene.background = new THREE.Color(0.,0.,0.);
    
    return(
      <>
        {<OrthographicCamera args={[-1,1,1,-1,1/Math.pow(2,53),1]}/>}
      </>
    )
  }