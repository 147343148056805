import * as THREE from 'three'
import { Moon } from "./Moon.js";
import { Seed } from './utils/Seed.js';
import { Atmosphere } from './Atmosphere.js';
import { Ring } from './Ring.js';
import { Area } from './Area.js'

export class Planet {

    constructor(system, data){

        this.parent = system;

        if(data == null)return;

        this.mass = data["mass"];
        this.distance = data["distance"];
        this.angle = data["angle"];
        this.radius = data["radius"];
        this.seed = new Seed(data["seed"]);
        this.type = data["type"];

        if(data["ring"] != "NONE"){
            this.ring = new Ring(this, data["ring"]);
        }

        this.atmosphere = new Atmosphere(this,data["atmosphere"]);

        let objects = [];
        data["objects"].forEach(object_data => {
            switch (object_data["object"]) {
                case "Moon": objects.push(new Moon(this,object_data))
                    break;
                default: console.log("Invalid object type")
                    break;
            }
        })
        this.objects = objects;

        let areas = [];
        data["areas"].forEach(area_data => {
            areas.push(new Area(this,area_data))
        })
        this.areas = areas;
    }
    
    getPosition(){
        return new THREE.Vector3(
            this.distance * Math.cos(this.angle),
            this.distance * Math.sin(this.angle),
            0)
    }

}