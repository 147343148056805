import {  MapControls, Line} from '@react-three/drei'
import * as THREE from 'three'
import { useThree, useLoader, useFrame} from '@react-three/fiber'
import { Bloom, EffectComposer} from '@react-three/postprocessing'
import { useEffect, useRef, useMemo,  Component, useState } from 'react'
import {  clamp, getColorByHisTemp } from '../../utils'
import SystemSkybox from './SystemScene/SystemSkybox'
import { BodyView } from './BodyScene/BodyView'
import circle_img from '../../assets/global/circle.png'
import AutoWindow from '../utils/AutoWindow'
import CanvasGUI from '../utils/CanvasGUI'
import DraggableFrame from '../utils/DraggableFrame'
import ComponentWidget from '../utils/widgets/ComponentWidget'
import { UI_COLOR } from '../../config/uiSettings'

function GroundScenePreView(props){
    //Build scene
    const aspect = window.innerWidth / window.innerHeight;
    let zoom = 50;
    let speed = 0.3;
    let camera_m = new THREE.PerspectiveCamera( 60,aspect, 0.15, 5000000 );
    camera_m.up.set(0, 0, 1);
    camera_m.position.set(0, 0, 100);
    camera_m.lookAt(0,0,0);

    const { gl,scene, camera } = useThree();

    const set = useThree((state) => state.set)
    useEffect(() => {
        set({ camera: camera_m})
      }, [])

      gl.autoClear = true
      gl.clear();
      gl.antialias = true;
      gl.logarithmicDepthBuffer = true;
      gl.setPixelRatio(window.devicePixelRatio);
      gl.toneMapping = THREE.ACESFilmicToneMapping;
      gl.toneMappingExposure = 0.5;

      scene.background = new THREE.Color(0x000000);
    
    return(
      <>
        {<MapControls enableDamping={false} enableRotate={true} maxAzimuthAngle={0.} minAzimuthAngle={0.} maxPolarAngle={0.3} minPolarAngle={0.0} minDistance={20} maxDistance={200} target={[0,0,0]} onChange={(e)=>{
            let distance = e.target.target.distanceTo(new THREE.Vector3(0,0,0));
            let speed = 0.1*(130-distance) 
            speed = clamp(speed,0.,1.);
            e.target.panSpeed = speed;
        }}
        />}
      </>
    )
}

export default class GroundScene extends Component {

    constructor(props){
        super(props);
        this.state = {
            area: props.area !== undefined ? props.area : null,
        }
    }

    render(){
        return(
            <>
                <ambientLight intensity={0.2}/>
                <GroundScenePreView/>

                <pointLight position={[0,0,5000]} color={getColorByHisTemp(this.state.area.parent.parent.objects[0].temperature)}/>
            </>
        )
    }

}