import {  OrbitControls, Line} from '@react-three/drei'
import * as THREE from 'three'
import { useThree, useLoader, useFrame} from '@react-three/fiber'
import { Bloom, EffectComposer} from '@react-three/postprocessing'
import { useEffect, useRef, useMemo,  Component, useState } from 'react'
import {  getColorByHisTemp } from '../../utils'
import SystemSkybox from './SystemScene/SystemSkybox'
import { BodyView } from './BodyScene/BodyView'
import circle_img from '../../assets/global/circle.png'
import AutoWindow from '../utils/AutoWindow'
import CanvasGUI from '../utils/CanvasGUI'
import DraggableFrame from '../utils/DraggableFrame'
import ComponentWidget from '../utils/widgets/ComponentWidget'
import { UI_COLOR } from '../../config/uiSettings'

function BodyScenePreView(props){
    //Build scene
    let camera_m = new THREE.PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 0.15, 5000 );
    camera_m.position.set(20, 0, 0);
    camera_m.up.set(0, 0, 1);

    const { gl,scene, camera } = useThree();

    const set = useThree((state) => state.set)
    useEffect(() => {
        set({ camera: camera_m})
      }, [])

      gl.autoClear = true
      gl.clear();
      gl.antialias = true;
      gl.logarithmicDepthBuffer = true;
      gl.setPixelRatio(window.devicePixelRatio);
      gl.toneMapping = THREE.ACESFilmicToneMapping;
      gl.toneMappingExposure = 0.5;

      scene.background = new THREE.Color(0x000000);
    
    return(
      <>
        <OrbitControls
          enableDamping={true} dampingFactor={0.05} screenSpacePanning={false} minDistance={1}
          maxDistance={200} panSpeed={0}
        />
        <SystemSkybox planet={props.body} reference={props.body.parent} size={1000.} sunColor={getColorByHisTemp(props.body.parent.objects[0].temperature)}/>
      </>
    )
}

function AreaView(props){

    const sphere = useRef();
    
    const latitude = props.area.latitude ?? 0.;
    const longitude = props.area.longitude ?? 0.;

    const texture = useLoader(THREE.TextureLoader, circle_img)

    let x = -Math.sin(latitude)*Math.cos(longitude);
    let y = Math.sin(latitude)*Math.sin(longitude);
    let z = Math.cos(latitude);

    let position = new THREE.Vector3(x,y,z).multiplyScalar(1.25);

    const { gl,scene, camera } = useThree();

    const [time, setTime] = useState(0);
    useFrame((state)=>{
        const { clock } = state;
        setTime(clock.getElapsedTime());
    })

    return(
        <>
        <group>
            <Line points={[[0.,0.,0.],[position.x,position.y,position.z]]} color={"gray"} lineWidth={5.}/>
            <mesh position={position} scale={0.05} ref={sphere}
            onClick={event => {
                if(props.getAreaSelected() != props.areaId){
                    props.setAreaSelected(props.areaId);
                }
                else{props.setAreaSelected(-1);
                }
            }}>
                <sphereGeometry/>
                <meshStandardMaterial color={"white"}/>
            </mesh>

            <mesh position={position} onUpdate={self => self.lookAt(new THREE.Vector3(0,0,0))} scale={0.3}>
                <planeGeometry/>
                <meshStandardMaterial transparent side={THREE.DoubleSide} map={texture}/>
            </mesh>
        </group>
        { props.getAreaSelected() == props.areaId && 
        <CanvasGUI key="Area" id="Area" type="CANVAS">
            <AutoWindow title="Area" id="Area" camera={camera} time={time} object_position={sphere?.current?.position} removeChild={props.removeChild} addChild={props.addChild}>
                <ComponentWidget title={"Area " + Math.round(latitude*10)/10 + "°/" + Math.round(longitude*10)/10 + "°"}>
                    <button onClick={(e)=>{e.preventDefault(); props.setScene(props.area); }}>AREA SCENE</button>
                </ComponentWidget>
             </AutoWindow>
        </CanvasGUI>}
    </>
    )
}


//List of JSX System components 
function getAreas(body, getAreaSelected, setAreaSelected, addChild, removeChild, setScene){
    const rows = [];
    for (let i = 0; i < body.areas.length; i++) {
        rows.push(
        <AreaView area={body.areas[i]} areaId={i} key={i}
            getAreaSelected={getAreaSelected} setAreaSelected={setAreaSelected} addChild={addChild} removeChild={removeChild} setScene={setScene}/>
        );
    }
    return( 
      <>{rows}</>
    )
  }

function BodyViewMemo(props){
    const body = useMemo(
        ()=>{return <BodyView body={props.body}/>},
        []
    )
    return(
        <>{body}</>
    )
}

export default class BodyScene extends Component {

    constructor(props){
        super(props);
        this.state = {
            body: props.body !== undefined ? props.body : null,
            areaSelected: -1,
            areaObject: undefined
        }
    }

    setAreaObject= (object) => {
        this.state.areaObject = object;
        this.setState(this.state);
    }

    setAreaSelected= (selection) => {
        this.state.areaSelected = selection;
        this.setState(this.state);
    }

    getAreaSelected = () => {
        return this.state.areaSelected;
    }

    render(){
        let sun_position = new THREE.Vector3(
            Math.cos(this.state.body.angle),
            Math.sin(this.state.body.angle),
            0).multiplyScalar(-50);
        return(
            <>
                <ambientLight intensity={0.15}/>
                <pointLight intensity={1.} color={getColorByHisTemp(this.state.body.parent.objects[0].temperature)??[1.,1.,1.]} position={sun_position??[0.,0.,0.]}/>
                <BodyScenePreView body={this.state.body}/>
                {getAreas(this.state.body, this.getAreaSelected, this.setAreaSelected, this.props.addChild,  this.props.removeChild, this.props.setScene)}
                <BodyViewMemo body={this.state.body}/>
                <EffectComposer>
                    <Bloom luminanceThreshold={0.} luminanceSmoothing={0.05} intensity={0.3}/>
                </EffectComposer>
            </>
        )
    }

}