import { Abstract } from "lamina/vanilla";

export default class AtmosphereShader extends Abstract {

    static u_seed = 0.3;
    static u_time = 0.;

    static u_color = [0.8,0.8,0.8];
    static u_density = 1.;

    static fragmentShader = ` 
        varying vec3 v_position;

        uniform float u_seed;
        uniform float u_time;

        uniform vec3 u_color;
        uniform float u_density;

        vec3 atmo_pal( float n, vec3 a, vec3 b, vec3 c, vec3 d )
        {
            return a+b*cos(6.28318*(c*n+d));
        }

        vec2 atmo_bandpass(float value, float minimum, float maximum){
            if(value < minimum || value > maximum){return vec2(minimum,value);}
            return vec2(1.0,value);
        }

        void main() {

            vec3 atmo_st = vec3(cos(u_time*.05))+v_position;
            float atmo_height = fbm(lamina_noise_perlin(lamina_noise_worley(v_position)*v_position*3.)*atmo_st+2.*v_position.z,4,3.0,3.5,0.7);
            atmo_height = abs(3.*(abs(max(2.*pow(atmo_height,4.),(1.-u_density))-(1.-u_density))));

            float alpha = clamp(max((u_density - .6),.0)*10.*atmo_height,.0,1.);
            vec4 f_colorfrag = vec4(u_color*(atmo_height*.5+.5),alpha); 

            return f_colorfrag;
        }
    `;

    static vertexShader = `  
        varying vec3 v_position;
        
        void main() {

            v_position = position;
            return position;
        }
    `;

    constructor(props) {
        super(AtmosphereShader, {
          name: "AtmosphereShader",
          ...props,
        });
      }
}