import * as THREE from 'three'
import { extend } from '@react-three/fiber'

class AreaCreatorViewShader extends THREE.ShaderMaterial {
  constructor() {
    super({
      vertexShader: `
      uniform sampler2D positions;
      uniform sampler2D assets;
      uniform float u_size;
      uniform vec3 u_seed;

      varying vec3 vNormal;
      varying vec3 vAssets;
      varying vec3 vPosition;
      varying vec3 v_displacedBitangent;
      varying vec3 v_displacedTangent;

      varying float v_ocean;

      uniform bool u_ocean;
      uniform vec4 u_oceanColor;
      uniform float u_oceanFloor;

      vec3 orthogonal(vec3 v) {
          return normalize(abs(v.x) > abs(v.z) ? vec3(-v.y, v.x, 0.0)
          : vec3(0.0, -v.z, v.y));
      }

      vec3 recalcNormals(vec3 newPos) {
          float offset = 1./u_size;
          vec3 tangent = orthogonal(normal);
          vec3 bitangent = normalize(cross(normal, tangent));
          vec3 neighbour1 = position + tangent * offset;
          vec3 neighbour2 = position + bitangent * offset;
          vec3 displacedNeighbour1 = texture2D(positions, neighbour1.xy).xyz;
          vec3 displacedNeighbour2 = texture2D(positions, neighbour2.xy).xyz;
          v_displacedTangent = displacedNeighbour1 - newPos;
          v_displacedBitangent = displacedNeighbour2 - newPos;
          return normalize(cross(v_displacedTangent, v_displacedBitangent));
      }

    
      void main() {

        vec3 pos = texture2D(positions, position.xy).xyz;
        vAssets = texture2D(assets, position.xy).xyz;

        vNormal = recalcNormals(pos);
        vPosition = pos.xyz;

        if(u_ocean){
          pos.z = clamp(pos.z,u_oceanFloor,9999.);
        }

        //pos.z -= pow(length(pos.xy+u_seed.xy)/8.,2.);
    
        gl_Position = projectionMatrix * modelViewMatrix * vec4( vec3(pos), 1.0 );
      }`,
      fragmentShader: `
      varying vec3 vPosition;
      varying vec3 vAssets;
      varying vec3 vNormal;
      varying vec3 v_displacedBitangent;
      varying vec3 v_displacedTangent;

      uniform bool u_grass;
      uniform vec4 u_grassColor;
      uniform float u_grassSlopeMax;
      uniform vec4 u_edgeColor;
      uniform vec4 u_plainColor;

      uniform bool u_ocean;
      uniform vec4 u_oceanColor;
      uniform float u_oceanFloor;

      float calculateMaxAbsZGradient(){
        return dot(vNormal, vec3(0.,0.,1.));
      }

      void main() {

        vec3 lightDirection = normalize(vec3(1.0, 1.0, 1.0));
        float intensity = clamp(dot(vNormal, lightDirection),-1.,1.);

        vec3 f_color = u_edgeColor.xyz;

        if(u_grass && calculateMaxAbsZGradient() > u_grassSlopeMax){
          f_color = u_grassColor.xyz;
          f_color = mix(f_color,u_plainColor.xyz,clamp(1.-vAssets.z*4.,0.,1.));
        }

        if(u_ocean && vPosition.z <= u_oceanFloor){
          f_color = mix(f_color, u_oceanColor.xyz, 1.);
          intensity = 1.-calculateMaxAbsZGradient()*.3;
        }

        intensity = round(abs(intensity) * 6.) / 6.;

        gl_FragColor = vec4(2.*intensity*f_color,1.0);
      }`,
      uniforms: {
        positions: { value: null },
        assets: {valuelue: null},

        u_size: {value: 1000},
        u_seed: {value: new THREE.Vector3(0.,0.,0.)},

        u_grass:{value: false}, u_grassColor:{value: new THREE.Vector4(0.,0.,0.,0.)}, u_grassSlopeMax:{value: 0.},
        u_edgeColor:{value: new THREE.Vector4(1.0,0.0,0.0,1.)}, 
        u_plainColor:{value: new THREE.Vector4(1.,1.,1.,1.)},

        u_ocean:{value: false}, u_oceanColor:{value: new THREE.Vector4(0.,0.,0.,0.)}, u_oceanFloor:{value: 0.},
      },
      transparent: true,
      blending: THREE.NormalBlending,
      depthWrite: true
    })
  }
}

extend({ AreaCreatorViewShader })
