import {useState, useEffect} from 'react'
import {UI_COLOR, UI_BORDER, UI_STYLES} from '../../../config/uiSettings'

export default function ComponentWidget(props){

    const [size, setSize] = useState(props.size ?? {width:window.innerWidth/4,height:window.innerHeight/4});
    const [position, setPosition] = useState(props.position ? props.position : [0,0]);

    useEffect(() => {
        function handleResize() {
            setSize(props.size ?? {width:window.innerWidth/4,height:window.innerHeight/4});
        }
        window.addEventListener('resize', handleResize);
    }, [])

    return(
        <div onPointerDown={event => {event.stopPropagation()}}
             style={{position:"absolute", overflow: "hidden", color:"white" ,width:size.width, height:size.height,
              top: position[1], left: position[0]}}>
                <div style={{backgroundColor:UI_COLOR.border, overflow: "hidden", userSelect:"none", borderBottom:UI_BORDER, width:"100%", height:"1.5em"}}>
                    <div style={Object.assign({},UI_STYLES.title,{float: "left"})}>
                        {props.title ?? ""}
                        {props.icon ? <img style={{position:"absolute"}} src={props.icon}/> : ""}
                    </div>
                </div>
                <div style={{backgroundColor:UI_COLOR.background, overflow: "auto"}}>
                    {props.children}
                </div>
        </div>
    )

}