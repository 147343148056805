import * as THREE from 'three'

export class Seed{

    constructor(seed_string){
        let seed_components = seed_string.split(';');
        this.value = new THREE.Vector3(seed_components[0] ?? 0,seed_components[1] ?? 0,seed_components[2] ?? 0);
    }

    getValue(){
        return this.value;
    }

    getX(){return this.value.getX();}
    getY(){return this.value.getY();}
    getZ(){return this.value.getZ();}

}