import { useFrame, extend } from "@react-three/fiber";
import { Fresnel, LayerMaterial } from "lamina";
import {  useRef, useMemo } from "react";

import StarShader from "../shaders/StarShader";
import { fragmentShader, vertexShader } from "./StarAtmosphere.shader";
import { BackSide } from "three";



extend({ StarShader})

export default function MoonView(props){
  const materialRef = useRef()
  const meshRef = useRef()

  const star_atmo_uniforms = useMemo(
    () => ({
      u_color: {
        value: props.starColor ?? new [1.,1.,1.],
      },
      u_time: {
        value: 0.0,
      },
      u_speed: {
        value: 0.2,
      }
    }),
    []
  );

  useFrame((state) => {
    const { clock } = state;
    materialRef.current.time = clock.getElapsedTime();
  });

  return (
    <>
    <mesh transparent scale={(props.scale*2.25)??3.5}>
      <icosahedronGeometry args={[1,20]} />
      <shaderMaterial transparent side={BackSide} depthWrite={false}
        fragmentShader={fragmentShader}
        vertexShader={vertexShader}
        uniforms={star_atmo_uniforms}
      />
    </mesh>
    <mesh transparent ref={meshRef} {...props}>
      <icosahedronGeometry args={[1,20]} />
      <LayerMaterial lighting="basic">
        <starShader ref={materialRef} time={0.0}  color={props.starColor ?? [1.,1.,1.]}/>
        <Fresnel mode="add" color={props.starColor ?? [1.,1.,1.]}/>
      </LayerMaterial>
    </mesh>
    </>
  )
}
