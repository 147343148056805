import { Abstract } from "lamina/vanilla";

export default class JovianPlanetShader extends Abstract {


    /**Seed of the object*/
    static u_seed = [0.,0.,0];

    static u_terrainGain = 0.5;
    static u_terrainLacunarity = 2.5;
    static u_terrainFrequency = 1.0;
    static u_terrainOctaves = 6;

    static u_bandFrequency = 1.0;

    /**How the clouds will be stretched*/
    static u_cloudsStretching = 4.0;

    /**Contrast of the noise (this is juste the factor a in noise=noise^a) */
    static u_contrast = 3.0;

    /**COLOR PALLET: Color(x)=A+Bcos(pi*x*C+D)*/
        static u_colorMat1A = [1.,1.,1.];
        static u_colorMat1B = [0.,0.,0.];
        static u_colorMat1C = [0.,0.,0.];
        static u_colorMat1D = [0.,0.,0.];
        static u_colorMat2A = [1.,1.,1.];
        static u_colorMat2B = [0.,0.,0.];
        static u_colorMat2C = [0.,0.,0.];
        static u_colorMat2D = [0.,0.,0.];
        /** If 0 then the color will be 1 else if 1 it will be 2. This is an interpolation factor.*/
        static u_colorMixFactor = 0.;

        /**x = x * this_factor*/
        static u_colorMat1Multiplier = 1.;
        /**x = x * this_factor*/
        static u_colorMat2Multiplier = 1.;
        /**color = color * this_factor*/
        static u_colorMat1Intensity = 1.;
        /**color = color * this_factor*/
        static u_colorMat2Intensity = 1.;
    //-------------------------------------------

    static fragmentShader = ` 
        varying vec3 v_position;
        varying vec3 v_neighbour1;

        uniform vec3 u_seed;

        uniform float u_bandFrequency;

        uniform int u_terrainOctaves;
        uniform float u_terrainGain;
        uniform float u_terrainLacunarity;
        uniform float u_terrainFrequency;

        uniform float u_contrast;
        uniform float u_cloudsStretching;

        //COLOR PALLET:
            uniform vec3 u_colorMat1A;
            uniform vec3  u_colorMat1B;
            uniform vec3  u_colorMat1C;
            uniform vec3  u_colorMat1D;
            uniform vec3  u_colorMat2A;
            uniform vec3  u_colorMat2B;
            uniform vec3  u_colorMat2C;
            uniform vec3  u_colorMat2D;
            uniform float u_colorMixFactor;

            uniform float u_colorMat1Multiplier;
            uniform float  u_colorMat2Multiplier;
            uniform float  u_colorMat1Intensity;
            uniform float  u_colorMat2Intensity;
        //-------------------------------------------

        vec2 bandpass(float value, float minimum, float maximum){
            if(value < minimum || value > maximum){return vec2(0.,0.);}
            return vec2(1.0,value);
        }

        float fbm(vec3 st, int octaves, float freq, float lacunarity, float gain) {
            float value = 0.0;
            float amplitude = 1.0;
            float frequency = freq;
            float max = 0.0;
            for (int i = 0; i < octaves; i++) {
              value += amplitude * lamina_noise_perlin((st)*frequency);
              frequency *= lacunarity;
              max += amplitude;
              amplitude *= gain;
            }
            return value/max;
        }

        float ridge_lines(vec3 st, float frequency,float min_value){
            float noise = fbm(st,3,frequency,2.,0.6);
            noise = -2.*abs(noise-.5)+1.;
            noise = pow(noise,1.);
            vec2 bp_noise = bandpass(noise, min_value, 1.);
            noise = bp_noise.y-min_value;
            return clamp(noise,0.,1.);
        }

        float sin_fbm(float st, int octaves, float freq, float lacunarity, float gain) {
            float value = 0.0;
            float amplitude = 1.0;
            float frequency = freq;
            float max = 0.0;
            for (int i = 0; i < octaves; i++) {
              value += amplitude * abs(sin((st)*frequency));
              frequency *= lacunarity;
              max += amplitude;
              amplitude *= gain;
            }
            return value/max;
        }

        float base_texture(vec3 st){

            vec3 copst = st;
            copst.z *= u_cloudsStretching;
            float noise = sin_fbm(st.z*5.,3,u_bandFrequency,2.8,0.45)*fbm(fbm(copst,3,1.,3.8,0.45)*st,3,1.,3.8,0.45);
            noise = lamina_noise_perlin(noise*st);

            noise = pow(noise,u_contrast);

            return noise;
        }

        vec3 pal( float n, vec3 a, vec3 b, vec3 c, vec3 d )
        {
            return a+b*cos(6.28318*(c*n+d));
        }

        float displace(vec3 position){
            return base_texture(position+u_seed*0.001);
        }

        void main() {

            float intensity = displace(v_position);

            vec3 colorMat1 = u_colorMat1Intensity*pal(intensity*u_colorMat1Multiplier,u_colorMat1A,u_colorMat1B,u_colorMat1C,u_colorMat1D);
            vec3 colorMat2 = u_colorMat2Intensity*pal(intensity*u_colorMat2Multiplier,u_colorMat2A,u_colorMat2B,u_colorMat2C,u_colorMat2D);
            vec3 baseFinalColor = mix(colorMat1,colorMat2,u_colorMixFactor);

            vec4 f_colorfrag = vec4(baseFinalColor*intensity,1.);

            return f_colorfrag;
        }
    `;

    static vertexShader = `  
        varying vec3 v_position;
        varying vec3 v_neighbour1;
        
        vec3 orthogonal(vec3 v) {
            return normalize(abs(v.x) > abs(v.z) ? vec3(-v.y, v.x, 0.0)
            : vec3(0.0, -v.z, v.y));
        }

        void calcNeighbours(vec3 newPos) {
            float offset = 0.0001;
            vec3 tangent = orthogonal(vec3(0.,1.,1.));
            vec3 neighbour1 = position + tangent * offset;
            v_neighbour1 = neighbour1;
        }

        void main() {

            v_position = position;

            calcNeighbours(position);

            return position;
        }
    `;

    constructor(props) {
        super(JovianPlanetShader, {
          name: "JovianPlanetShader",
          ...props,
        });
      }
}