import { Abstract } from "lamina/vanilla";
import { randFloat } from "three/src/math/MathUtils";

export default class SkyboxShader extends Abstract {

    static u_time = 0.0;
    static u_seed = [0.,0.,0.];

    static u_sunColor =  [1.,1.,1.];
    static u_sunPosition =  [1.,1.,1.];

    /** Disk thickness from 0. to 1. */
    static u_galaxyDiskThickness = 0.0;

    /**Oscillation speed of the solar blur */
    static u_solarBlurSpeed = 0.2;
    /** Amplitude of the blur oscillation */
    static u_solarBlurAmplitude = 0.1;
    /** Solar blur width */
    static u_solarBlurSigma = 0.03;


    /** If the system is in a nebulae or not */
    static u_nebulae = false;
    static u_nebulaeColor = [randFloat(0.,1.),randFloat(0.,1.),randFloat(0.,1.)];
    /** Brightness of the nebulae */
    static u_nebulaeIntensity = 2.;
    /**Nebulae opacity */
    static u_nebulaeOpacity = 1.;
    /**Nebulae noise frequency */
    static u_nebulaeFrequency = 1.;
    /**10 base octaves * factor*/
    static u_nebulaeOctavesFactor = 1.;

    static u_starsRarity = 1.2;

    static u_planetsPosition0 = [1.,1.,1.];
    static u_planetsPosition1 = [1.,1.,1.];
    static u_planetsPosition3 = [1.,1.,1.];
    static u_planetsPosition4 = [1.,1.,1.];
    static u_planetsPosition5 = [1.,1.,1.];
    static u_planetsPosition6 = [1.,1.,1.];
    static u_planetsPosition7 = [1.,1.,1.];
    static u_planetsPosition8 = [1.,1.,1.];
    static u_planetsPosition9 = [1.,1.,1.];
    static u_planetsPosition10 = [1.,1.,1.];
    static u_planetsCount = 0;
   

    static fragmentShader = ` 
        varying vec3 v_position;

        uniform vec3 u_planetsPosition0;
        uniform vec3 u_planetsPosition1;
        uniform vec3 u_planetsPosition2;
        uniform vec3 u_planetsPosition3;
        uniform vec3 u_planetsPosition4;
        uniform vec3 u_planetsPosition5;
        uniform vec3 u_planetsPosition6;
        uniform vec3 u_planetsPosition7;
        uniform vec3 u_planetsPosition8;
        uniform vec3 u_planetsPosition9;
        uniform vec3 u_planetsPosition10;
        uniform int u_planetsCount;

        uniform vec3 u_sunColor;
        uniform vec3 u_sunPosition;

        uniform float u_time;
        uniform vec3 u_seed;

        uniform float u_galaxyDiskThickness;

        uniform float u_solarBlurSpeed;
        uniform float u_solarBlurAmplitude;
        uniform float u_solarBlurSigma;

        uniform bool u_nebulae;
        uniform vec3 u_nebulaeColor;
        uniform float u_nebulaeIntensity;
        uniform float u_nebulaeOpacity;
        uniform float u_nebulaeFrequency;
        uniform float u_nebulaeOctavesFactor;

        uniform float u_starsRarity;


        vec3 pal( float n, vec3 a, vec3 b, vec3 c, vec3 d )
        {
            return a+b*cos(6.28318*(c*n+d));
        }

        float skyboxFbm(vec3 x, float w, float k0, float fs, float v, float a)
        {
            float sum = 0.;
            float max_sum = 0.;
            for (float k = 1.; k < (w+1.)*1.; k++) {
                sum = sum + (pow(k0+fs*k,-v))*(lamina_noise_simplex((k0+fs*k)*x));
                max_sum = max_sum + (pow(k0+fs*k,-v));
            }
            sum = pow(sum,a);
            max_sum = pow(max_sum,a);
            return sum/(max_sum);
        }

        float verticalMask(vec3 position){
            return smoothstep(clamp(1.-u_galaxyDiskThickness,0.,1.),1.0,1.-abs(position.z));
        }

        float getSunIntensity(vec3 position, vec3 star_position){
    
            if(length(star_position)==0.){return 0.;}
            vec3 spos = 1.*normalize(star_position);
            float sdot = dot(spos,position);
            float blur_intensity = sdot;
            blur_intensity = clamp(sdot,0.,1.);
            float sigma = u_solarBlurSigma*(1.+u_solarBlurAmplitude*sin(u_solarBlurSpeed*u_time));
            blur_intensity =  exp(-pow((blur_intensity-1.),2.)/(2.*sigma*sigma));


            float sun_intensity = sdot;
            sun_intensity = smoothstep(1.*(1.-sigma*0.002), 1.0, sun_intensity) ; 
      
            return blur_intensity+sun_intensity;
      
        }

        float getPlanetsIntensity(vec3 position){
            float intensity = 0.;
            vec3 planetsPositions[11] = vec3[11](
                u_planetsPosition0,u_planetsPosition1,u_planetsPosition2,u_planetsPosition3,
                u_planetsPosition4,u_planetsPosition5,u_planetsPosition6,u_planetsPosition7,
                u_planetsPosition8,u_planetsPosition9,u_planetsPosition10
            );
            for(int i = 0; i < u_planetsCount; i++)
            {
                vec3 ppos = normalize(planetsPositions[i]);
                float pdot = (dot(ppos,position));
                float sigma = 0.015*(1.+0.*sin(u_solarBlurSpeed*u_time));
                pdot = smoothstep(1.*(1.-sigma*0.002), 1.0, pdot) ; 
                intensity += pdot;
            }
            return intensity;
        }
        
        float getStarsIntensity(vec3 position){
            return skyboxFbm(position,7.,40.,30.,1.,15.*(u_starsRarity))*20.;
        }

        float getDustIntensity(vec3 position){
            return verticalMask(position)*(skyboxFbm(position+vec3(50.),8.,0.,1.,1.,4.)*1.);
        }

        float getDiskFilament(vec3 position){
            return verticalMask(position)*(skyboxFbm(position,8.,0.,4.,1.,4.));
        }

        vec4 getNebulaeColor(vec3 position){
            float noise = skyboxFbm(position+vec3(100.),10.*u_nebulaeOctavesFactor,0.,u_nebulaeFrequency,1.,4.);
            noise = smoothstep(0.005,1.0,noise)*2.;
            return vec4(pal(noise*3.,vec3(.35,.35,.35),vec3(.35,.35,.35),vec3(1.,1.,1.),u_nebulaeColor)*noise*u_nebulaeIntensity,noise*u_nebulaeOpacity);
        }
        
        void main() {
            vec3 sun_color = u_sunColor * getSunIntensity(v_position, u_sunPosition);
            vec3 planets_color = u_sunColor * getPlanetsIntensity(v_position);

            vec3 posseed = u_seed+v_position;

            vec3 stars_noise = getStarsIntensity(posseed)*vec3(1.);
            vec3 dust_noise = getDustIntensity(posseed)*vec3(1.);
            vec3 disk_noise = getDiskFilament(posseed)*vec3(1.);


            vec3 global_color = sun_color+stars_noise+dust_noise+disk_noise+planets_color;
            if(u_nebulae){
                vec4 nebulae_noise = getNebulaeColor(posseed)*1.;
                global_color = sun_color+planets_color+(clamp(1.-nebulae_noise.w*5.,0.,1.))*(stars_noise+dust_noise+disk_noise)+nebulae_noise.xyz;
            }

            

            vec4 f_colorfrag = vec4(global_color,1.0); 

            return f_colorfrag;
        }
    `;

    static vertexShader = `  
        varying vec3 v_position;
        
        void main() {

            v_position = position;
            return position;
        }
    `;

    constructor(props) {
        super(SkyboxShader, {
          name: "SkyboxShader",
          ...props,
        });
      }
}