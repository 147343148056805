import {  CameraControls, Grid, Instance, Instances} from '@react-three/drei'
import * as THREE from 'three'
import { useThree, useFrame} from '@react-three/fiber'
import { useEffect, useState,  Component, useMemo } from 'react'
import { Particle } from './Particle'
import { Bloom, EffectComposer } from '@react-three/postprocessing'

function ParticleView(props){
    return(
        <Instance {...props} scale={(props.scale ?? 1.)*0.02}/>
    )
}

function getParticles(particles){
    let rows = []
    for (let i = 0; i < particles.length; i++) {
        const particle = particles[i];
        rows.push(
            <ParticleView key={i} color={particle.color} position={particle.position}/>
        )
    }
    return(
        <Instances>
            <meshBasicMaterial/>
            <sphereGeometry args={[1.,10.]}/>
            {rows}
        </Instances>
    )
}

function mapFloatToColor(float){
    const A = new THREE.Vector3(.6,.6,.6)
    const B = new THREE.Vector3(.4,.4,.4)
    const C = new THREE.Vector3(1.,.7,.4)
    const D = new THREE.Vector3(0.,0.15,0.2)

    const r = A.getComponent(0)+B.getComponent(0)*Math.cos(Math.PI*2*(C.getComponent(0)*float+D.getComponent(0)))
    const g = A.getComponent(1)+B.getComponent(1)*Math.cos(Math.PI*2*(C.getComponent(1)*float+D.getComponent(1)))
    const b = A.getComponent(2)+B.getComponent(2)*Math.cos(Math.PI*2*(C.getComponent(2)*float+D.getComponent(2)))
    return new THREE.Color(r,g,b)
}

function GravSimScenePreview(props){
    //Build scene
    const aspect = window.innerWidth / window.innerHeight;
    let camera_m = new THREE.PerspectiveCamera( 60,aspect, 0.015, 5000000 );
    camera_m.up.set(0, 0, 1);
    camera_m.position.set(0, 0, 10);
    camera_m.lookAt(0,0,0);
  
    const { gl,scene } = useThree();
  
    const set = useThree((state) => state.set)
    useEffect(() => {
        set({ camera: camera_m})
      }, [])
  
      gl.antialias = true;
      gl.logarithmicDepthBuffer = true;
      gl.setPixelRatio(window.devicePixelRatio);
      gl.toneMapping = THREE.ACESFilmicToneMapping;
      gl.toneMappingExposure = 1.;
      gl.preserveDrawingBuffer = true;
  
      scene.background = new THREE.Color(0.,0.,0.);
    
    return(
      <>
        {<CameraControls />}
      </>
    )
  }

  export default function GravSimScene(props) {

    
    const dt = 20;

    const [particles,setParticles] = useState([]);
    const PARTICLES = useMemo(()=>{return getParticles(particles,dt)})

    useEffect(()=>{
        setParticles(generateParticles())
    }, [])


    useEffect(() => {
        const intervalId = setInterval(() => {
          setParticles((prevParticles) => verlet(prevParticles,dt));
        }, dt);
    
        return () => clearInterval(intervalId);
      }, []);


    return(
        <>
            <GravSimScenePreview/>
            {PARTICLES}
            <EffectComposer>
                    <Bloom luminanceThreshold={0.} luminanceSmoothing={0.25} intensity={1.}/>
                </EffectComposer>
        </>
    )
}

function generateParticles(){
    const NUMBER = 500;
    const RADIUS = 4.5;
    let particles = [];
    for (let i = 0; i < NUMBER; i++) {
        let theta = Math.random()*Math.PI;
        let phi = Math.random()*2*Math.PI
        let radius = RADIUS*Math.random()
        let particle = new Particle(1,
            new THREE.Vector3(radius*Math.cos(phi)*Math.sin(theta),radius*Math.sin(phi)*Math.sin(theta),radius*Math.cos(theta)),
            new THREE.Vector3(-radius*Math.sin(phi)*0.0,radius*Math.cos(phi)*0.0,0.)
            )
        particle.color = mapFloatToColor(radius*0.5)
        particles.push(particle)
    }
    return particles;
}

//return updated particles
function verlet(particles,DT){
    const dt = DT/1000;
    const G = 1;
    const smooth_factor = 10.;
    let new_particles = []
    particles.forEach(p1 => {
        let F_sum = new THREE.Vector3(0.,0.,0.);
        particles.forEach(p2 => {
            if(!p2.equals(p1)){
                let diff = p2.position.clone().sub(p1.position);
                let length = diff.length()
                let force = G * p1.mass * p2.mass * 1 / (length*length+smooth_factor*smooth_factor);
                    if(length != 0){
                        F_sum.add(diff.normalize().multiplyScalar(force));
                    }
            }
        });
        let new_pos = p1.position.clone().multiplyScalar(2.).sub(p1.old_position).add(F_sum.multiplyScalar(dt*dt/p1.mass))
        let new_p1 = p1.clone()
        new_p1.old_position = new_p1.position
        new_p1.position = new_pos
        new_particles.push(new_p1)
    });
    return new_particles
}