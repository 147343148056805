export class Particle {

    constructor(mass, position, velocity, dt=1., color="white"){
        this.mass = mass;
        this.position = position;
        this.old_position = position.clone().sub(velocity.clone().multiplyScalar(dt));
        this.dt = dt;
        this.color = color;
    }

    clone(){
        return new Particle(this.mass, this.position.clone(), this.old_position.clone(), this.dt, this.color)
    }

    equals(p2){
        return p2 == this.mass && this.position.equals(p2.position) && this.old_position.equals(p2.old_position) 
    }

}