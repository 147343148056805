import {  CameraControls, Grid} from '@react-three/drei'
import * as THREE from 'three'
import { useThree, useFrame} from '@react-three/fiber'
import { useEffect,  Component } from 'react'

import { folder,useControls } from 'leva'
import CanvasGUI from '../../utils/CanvasGUI'
import ComponentWidget from '../../utils/widgets/ComponentWidget'

import './AreaCreatorShaders/areaCreatorViewShader'
import './AreaCreatorShaders/areaCreatorSimulationShader'

import { ListAreaView } from '../GroundScene/AreaView'
import ImageWidget from '../../utils/widgets/ImageWidget'



function PassingAreaView(props){

  
  const [{
    //oneTile
  }, setLevaGlob] = useControls(()=>({
    RenderSettings:folder({
      //oneTile: true,
    })
  }))

  const uniforms_global = {
    oneTile: true,
  }

  const [{

    u_seed, u_globalFrequency,
    u_terrainOctaves, u_terrainFrequency, u_terrainLacunarity, u_terrainGain, u_terrainExp, u_terrainIntensity,
    u_ridgeOctaves, u_ridgeIntensity, u_ridgeFrequency, u_ridgeLacunarity, u_ridgeGain, u_ridgeWidth, u_ridgeSmoothstepMax,
    u_cratersLevel, u_cratersFrequency, u_cratersLacunarity, u_cratersGain, u_cratersIntensity, u_cratersLimit,

    u_skyColor,
    u_grass, u_grassColor, u_grassSlopeMax,
    u_edgeColor, u_plainColor,
    u_ocean, u_oceanColor, u_oceanFloor,

    u_assets,u_assetsOceanEffect, u_assetsNoiseIntensity, u_assetsNoiseFrequency, u_assetsNoisePow,
  }, setLeva] = useControls(()=>({

    u_seed: [0.,0.,0.],
    u_globalFrequency: 1.05,
    Terrain:folder({
      u_terrainOctaves: 15,
      u_terrainFrequency: 2.,
      u_terrainLacunarity: 1.6,
      u_terrainGain: 0.5,
      u_terrainExp: 2.,
      u_terrainIntensity: 3.,
    }),
    u_ridgeOctaves: 10,
    Ridge:folder({
      u_ridgeIntensity: {value: 5., render: (get) => get('u_ridgeOctaves') > 0},
      u_ridgeFrequency: {value: 1., render: (get) => get('u_ridgeOctaves') > 0},
      u_ridgeLacunarity: {value: 1.6, render: (get) => get('u_ridgeOctaves') > 0},
      u_ridgeGain: {value: 0.6, render: (get) => get('u_ridgeOctaves') > 0},
      u_ridgeWidth: {value: 0.95, render: (get) => get('u_ridgeOctaves') > 0},
      u_ridgeSmoothstepMax: {value: 0.8, render: (get) => get('u_ridgeOctaves') > 0},
    }),
    u_cratersLevel: 0,
    Craters:folder({
      u_cratersFrequency: {value: 1., render: (get) => get('u_cratersLevel') > 0},
      u_cratersLacunarity: {value: 2., render: (get) => get('u_cratersLevel') > 0},
      u_cratersGain: {value: 0.4, render: (get) => get('u_cratersLevel') > 0},
      u_cratersIntensity: {value: 1., render: (get) => get('u_cratersLevel') > 0},
      u_cratersLimit: {value: 0.4, render: (get) => get('u_cratersLevel') > 0},
    }),

    u_skyColor: {r:37,g:63,b:101,a:1.},
    u_edgeColor: {r:98,g:82,b:68,a:1.0},

    u_grass: true,
    Grass:folder({
      u_grassColor: {value:{r:37,g:56,b:36,a:1.}, render: (get) => get('u_grass')},
      u_plainColor: {value:{r:140,g:117,b:94,a:1.}, render: (get) => get('u_grass')},
      u_grassSlopeMax: {value:0.85, render: (get) => get('u_grass')},
    }),

    u_ocean: true,
    Ocean:folder({
      u_oceanColor: {value: {r:14,g:37,b:56,a:1.0}, render: (get) => get('u_ocean')},
      u_oceanFloor: {value: 0.82, render: (get) => get('u_ocean')},
  }),

  u_assets: true,
    Assets:folder({
      u_assetsOceanEffect: {value:true},
      u_assetsNoiseIntensity: {value:0.},
      u_assetsNoiseFrequency: {value:2.5},
      u_assetsNoisePow: {value:4.},
    })

  }))
  useFrame((state)=>{
    state.scene.background = new THREE.Color(u_skyColor.r/255,u_skyColor.g/255,u_skyColor.b/255);
  })

  const uniforms_simulation = {u_seed:{value: new THREE.Vector3(u_seed[0],u_seed[1],u_seed[2])}, u_globalFrequency:{value: u_globalFrequency},
    u_terrainOctaves:{value: u_terrainOctaves}, u_terrainFrequency:{value: u_terrainFrequency}, u_terrainLacunarity:{value: u_terrainLacunarity},
    u_terrainGain:{value: u_terrainGain}, u_terrainExp:{value: u_terrainExp}, u_terrainIntensity:{value: u_terrainIntensity},
    u_ridgeGain:{value: u_ridgeGain}, u_ridgeWidth:{value: u_ridgeWidth}, u_ridgeSmoothstepMax:{value: u_ridgeSmoothstepMax},
    u_ridgeLacunarity:{value: u_ridgeLacunarity}, u_ridgeFrequency:{value: u_ridgeFrequency}, u_ridgeIntensity:{value: u_ridgeIntensity},
    u_ridgeOctaves:{value: u_ridgeOctaves},
    u_cratersLevel:{value: u_cratersLevel}, u_cratersFrequency:{value: u_cratersFrequency},u_cratersLimit: {value : u_cratersLimit}, u_cratersLacunarity: {value : u_cratersLacunarity}, u_cratersGain: {value : u_cratersGain},u_cratersIntensity: {value : u_cratersIntensity}}

  const uniforms_view = {u_grass:{value: u_grass}, u_grassColor:{value: u_grassColor}, u_grassSlopeMax:{value: u_grassSlopeMax},
  u_edgeColor:{value: u_edgeColor}, u_plainColor:{value:u_plainColor},
  u_oceanColor:{value: u_oceanColor}, u_oceanFloor:{value: u_oceanFloor}, u_ocean:{value: u_ocean},
  }

  const uniforms_assets = {u_assets:{value: u_assets}, u_assetsOceanEffect:{value:u_assetsOceanEffect},
  u_assetsNoiseIntensity: {value: u_assetsNoiseIntensity}, u_assetsNoiseFrequency:{value:u_assetsNoiseFrequency}, u_assetsNoisePow:{value:u_assetsNoisePow} }

  var uniforms_view_normalized = {};
  uniforms_view_normalized["u_skyColor"] = u_skyColor;

  Object.keys(uniforms_view).forEach(function(key, index) {
    uniforms_view_normalized[key] = uniforms_view[key].value;
  });

  var uniforms_simulation_normalized = {};

  Object.keys(uniforms_simulation).forEach(function(key, index) {
    uniforms_simulation_normalized[key] = uniforms_simulation[key].value;
  });

  var uniforms_assets_normalized = {};

  Object.keys(uniforms_assets).forEach(function(key, index) {
    uniforms_assets_normalized[key] = uniforms_assets[key].value;
  });

  return(
    <>
    <ListAreaView setTexture={props.setTexture} uniforms_assets={uniforms_assets} uniforms_global={uniforms_global} uniforms_simulation={uniforms_simulation} uniforms_view={uniforms_view}/>
    <CanvasGUI position={[10,10]}> 
      <ComponentWidget title="Save/Load" size={[window.innerWidth/6,window.innerHeight/6]}>
        <DownloadButton {...uniforms_view_normalized} {...uniforms_simulation_normalized} {...uniforms_assets_normalized}/>

        <br/>
        <div style={{color:"white"}}>
        <b>IMPORT FROM JSON</b>: 
        <input type='file' accept='application/json' onChange={
            async event => {
                const json = JSON.parse(await event.target.files[0].text());

                setLeva(json);
            }
        } />
        </div>
      </ComponentWidget>
    </CanvasGUI>
    <CanvasGUI position={[10,10+window.innerHeight/6]}> 
      <ComponentWidget title="Heightmap" size={[window.innerWidth/6,window.innerHeight/6]}>
        <DownloadTexturesButton addChild={props.addChild} removeChild={props.removeChild} getTexture={props.getTexture}/>
      </ComponentWidget>
    </CanvasGUI>
  </>
  )

}

export default class AreaCreatorScene extends Component {

    constructor(props){
        super(props);
        this.state = {
            texture: null,
        }
    }

    shouldComponentUpdate(nextProps, nextState){
      if(nextState.texture !== this.state.texture){return true;}
      else{return false;}
    }
    
    getTexture = () => {
      return this.state.texture;
    }

    setTexture = (texture) => {
      this.setState({texture: texture})
    }

    render(){
        return(
            <>
                <Grid infiniteGrid cellSize={0.1} rotation={[Math.PI/2,0,0]} fadeDistance={20} sectionColor={"black"}/>
                <ambientLight intensity={0.2}/>
                <AreaCreatorScenePreView/>
                <PassingAreaView addChild={this.props.addChild} removeChild={this.props.removeChild} setTexture={this.setTexture} getTexture={this.getTexture}/>
                <pointLight position={[5000,5000,5000]} color={"white"}/>
            </>
        )
    }
}

function DownloadButton(props){

  let data = props;
  let jsonData = JSON.stringify(data);

  let file = new Blob([jsonData],{type: 'application/json'});

  return(
      <a href={URL.createObjectURL(file)} download={"area.json"}> 
          <div style={{color:"white"}}>
          -<b>EXPORT TO JSON</b>-
          </div>
      </a>
  )
}

function DownloadTexturesButton(props){

  return(
        <>
       {props.getTexture != undefined && 
       <>
       <ImageWidget addChild={props.addChild} removeChild={props.removeChild}
       src={props.getTexture()} size={{width:window.innerWidth/10, height:window.innerWidth/10}}/>
       <a href={props.getTexture()} download={"heightmap.jpg"}> 
          <div style={{color:"white"}}>
          -<b>DOWNLOAD HEIGHTMAP</b>-
          </div>
      </a>
      </>
       }
      </>
  )
}

function AreaCreatorScenePreView(props){
  //Build scene
  const aspect = window.innerWidth / window.innerHeight;
  let camera_m = new THREE.PerspectiveCamera( 60,aspect, 0.015, 5000000 );
  camera_m.up.set(0, 0, 1);
  camera_m.position.set(7, 0, 4);
  camera_m.lookAt(0,0,0);

  const { gl,scene } = useThree();

  const set = useThree((state) => state.set)
  useEffect(() => {
      set({ camera: camera_m})
    }, [])

    gl.antialias = true;
    gl.logarithmicDepthBuffer = true;
    gl.setPixelRatio(window.devicePixelRatio);
    gl.toneMapping = THREE.ACESFilmicToneMapping;
    gl.toneMappingExposure = 1.;
    gl.preserveDrawingBuffer = true;

    scene.background = new THREE.Color(0.7*173./255.,0.7*124./255.,0.7*84./255.);
  
  return(
    <>
      {<CameraControls />}
    </>
  )
}