
import { useMemo } from "react";
import * as THREE from 'three'

import { fragmentShader, vertexShader } from "./AtmosphereMaterial.shader";

export default function AtmosphereMaterial(props){
 const uniforms = useMemo(
    () => ({
      u_color: {
        value: props.color ?? new THREE.Vector3(1.0,1.0,1.0)
      },
      u_pressure:{
        value: props.pressure ?? 1.
      }
    }),
    []
  );

  return (
      <shaderMaterial transparent side={THREE.BackSide} depthWrite={false}
        fragmentShader={fragmentShader}
        vertexShader={vertexShader}
        uniforms={uniforms}
      />
  )
}
