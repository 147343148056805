import { Abstract } from "lamina/vanilla";

export default class GalaxyShader extends Abstract {

    //Wb : from 0.05 to 1.0 (nombre rings)
    //Wn : from 1.0 to 10. (courbure)
    static u_Wb = 0.8;
    static u_Wn = 10.;
    //Arm width (positive)
    static u_Aw = 6.2;
    //Galaxy thickness
    static u_z0 = 0.05;
    //Exponential Disk Drop-off
    static u_rh = 0.4;
    //Bulge intensity multiplier
    static u_Im = 0.55;
    //Bulge size factor
    static u_r0 = 0.35;

    static u_deformationOffset = [0.,0.,0.];

    static u_raymarchSteps = 200;
    //when raymarching stops using the shape mask
    static u_raymarchPsy0Min = 0.001;
    //when raymarching stops on the z-axis, times z0
    static u_raymarchHeightCutOff = 2.

    static u_twirlAngle = 0.0;
    static u_twirlIntensity = 1.0;

    static u_galaxyIntensityPowFactor = 1.5;
    static u_galaxyIntensityGlobal = 1.0;

    static u_backgroundIntensityPowFactor = 1.;
    static u_backgroundIntensityGlobal = 1.;
    static u_backgroundOctaves = 2.;


    
    static u_camPos = [0.,0.,0.]
    static u_time = 0.;
    static u_seed = [10.,0.,0.];

    static fragmentShader = ` 

        varying vec3 v_position;

        uniform float u_time;
        uniform vec3 u_seed;

        uniform float u_Wb;
        uniform float u_Wn;
        uniform float u_Aw;
        uniform float u_z0;
        uniform float u_rh;
        uniform float u_Im;
        uniform float u_r0;

        uniform vec3 u_deformationOffset;

        uniform float u_raymarchSteps;
        uniform float u_raymarchPsy0Min;
        uniform float u_raymarchHeightCutOff;

        uniform float u_twirlAngle;
        uniform float u_twirlIntensity;

        uniform float u_galaxyIntensityPowFactor;
        uniform float u_galaxyIntensityGlobal;

        uniform float u_backgroundIntensityPowFactor;
        uniform float u_backgroundIntensityGlobal;
        uniform float u_backgroundOctaves;

        uniform vec3 u_camPos;


        //-----------------PROCEDURAL NOISES---------------

        
        const mat2 m2 = mat2(.8,.6,-.6,.8);
        
        float noise(in vec3 p)
        {
           
            p*=2.;
            float res=0.;
            float f=1.;
            for( int i=0; i< 3; i++ ) 
            {		
                p.xy=m2*p.xy;
                p=p.zxy*f+.6;     
                f*=1.15;
                res+=sin(p.y+1.3*sin(1.2*p.x)+1.7*sin(1.7*p.z));
            }        	
            return res/3.;
        
        }
        
        
        float fbmdisk( vec3 p ) {
            
            float f=2.;   
            float r = 0.0;	
            for(int i = 1;i<5;i++){	
                r += abs(noise( p*(f) ))/f;       
                f +=f;
                
            }
            return 1.2/(.07+r);
        }

        float galaxyFbm(vec3 x, float w, float k0, float fs, float v, float a)
        {
            float sum = 0.;
            float max_sum = 0.;
            for (float k = 1.; k < (w+1.); k++) {
                sum = sum + (pow(k0+fs*k,-v))*(lamina_noise_simplex((k0+fs*k)*x));
                max_sum = max_sum + (pow(k0+fs*k,-v));
            }
            sum = pow(sum,a);
            max_sum = pow(max_sum,a);
            return sum/(max_sum);
        }
        
        float galaxyFbmInvert(vec3 x, float w, float k0, float fs, float v, float a)
        {
            float sum = 0.;
            float max_sum = 0.;
            for (float k = 1.; k < (w+1.); k++) {
                sum = sum + (pow(k0+fs*k,-v))*(lamina_noise_simplex((k0+fs*k)*x));
                max_sum = max_sum + (pow(k0+fs*k,-v));
            }
            sum = pow(sum,a);
            max_sum = pow(max_sum,a);
            return 1.0/(sum*max_sum);
        }

        float fbm(vec3 st, int octaves, float freq, float lacunarity, float gain) {
            float value = 0.0;
            float amplitude = 1.0;
            float frequency = freq;
            float max = 0.0;
            for (int i = 0; i < octaves; i++) {
              value += amplitude * lamina_noise_simplex((st)*frequency);
              frequency *= lacunarity;
              max += amplitude;
              amplitude *= gain;
            }
            return 1./(value*max);
        }

        //----------------------SPIRAL ARM--------------------

        float spiralArmStructureShape(float r, float Wb,float Wn)
        {
            return atan((exp(1./r))/(Wb))*2.*Wn;
        }

        float spiralArmStructure(float Aw, float Wb,float Wn,float number_factor,vec3 position)
        {
            float pixelRadius =  length(position.xy);
            float value = 0.;
            float n = 7.;
            for(float i=0.;i<n;i++) {
                float pixelAngle = atan(number_factor*position.y,number_factor*position.x)+(i*2.*3.141592);
                value = max(value,pow((1.-(1./(3.141592))*abs(spiralArmStructureShape(pixelRadius,Wb,Wn)-pixelAngle)),Aw));
            }
            return value;
        }

        mat2 twirl(float radius, float angle){
            return mat2(cos(u_twirlIntensity*radius*angle),-sin(u_twirlIntensity*radius*angle),sin(u_twirlIntensity*radius*angle),cos(u_twirlIntensity*radius*angle));
        }

        //-------------------INTENSITY RULES-----------------
        float addIntensityProducingComponents(float I0,float I1,float psy)
        {
            return I0+I1*psy;
        }

        float addIntensityDecreasingComponents(float I0,float I1,float psy)
        {
            return I0*exp(-I1*psy);
        }

        //return (new_intensity, disk_intensity)
        vec2 updateIntensity(float I0, float disk_intensity, vec3 position){

            vec3 offset = u_deformationOffset;
            float r = length(position+offset);
            float angle = u_twirlAngle;
            position = vec3(position.xy*twirl(r,angle),position.z);
            position += offset;

            float spiralArmsFactor = spiralArmStructure(u_Aw,u_Wb,u_Wn,-1.0,position)+spiralArmStructure(u_Aw,u_Wb,u_Wn,1.0,position);

            vec3 twirled_position = position + vec3(cos(u_time*0.05+1.),cos(-u_time*0.03+2.),0.);
            

            float diskIntensityModifier = pow((exp(position.z/u_z0)+exp(-position.z/u_z0)/2.),-2.) * exp(-r/u_rh);

            float psy0 = diskIntensityModifier*spiralArmsFactor;

            float rb = r/u_r0;
            float bulge_component = 0.1*u_Im*pow(rb,-2.155)*exp(-pow(rb,0.25));

            float dustDiskComponent=0.;
            float intensity = I0+bulge_component;
            if(abs(position.z) < u_raymarchHeightCutOff*u_z0 && psy0 > u_raymarchPsy0Min){
                //disk
                dustDiskComponent =  35.*pow(fbm(twirled_position,1,3.,1.5,0.6),1.);
                //filaments
                intensity = addIntensityProducingComponents(intensity,u_Im,2.0*psy0*fbmdisk(twirled_position+vec3(10.)));
            }

            //Dust & Disk
            intensity = addIntensityDecreasingComponents(intensity,u_Im,psy0*dustDiskComponent);

            return vec2(intensity,disk_intensity+psy0*dustDiskComponent);
        }

        //-------------------RAYMARCH-------------------------------


        //return (global_intensity,disk_intensity)
        vec2 raymarch( )
        {
            float intensity = 0.0;
            float disk_intensity = 0.0;

            vec3 u = normalize(v_position);
            vec3 o = u_camPos+v_position*0.01-u_deformationOffset;
            float r = u_rh*10.;

            float disc = pow(dot(u,o),2.) - (pow(length(o),2.)-r*r);
            
            float numSteps = 0.;
            float nss = u_raymarchSteps;
            vec3 rayOrigin = o;
            float stepSize = 2.*r/(nss);

            if(disc >= 0. && length(o)>r){
                numSteps = nss;
                stepSize = 2.*r/(numSteps);
                rayOrigin = o+u*(-dot(u,o)-sqrt(disc));
            }else if(length(o)<r){
                numSteps = nss;
                //stepSize = (2.*r-length(o))/numSteps;
                stepSize = 2.*r/(numSteps);
            }else{         
                return vec2(0.0,0.0);
            }

            vec3 rayDirection = normalize(v_position);
            vec3 offset = vec3(0.0);

            rayOrigin = rayOrigin + rayDirection * stepSize * numSteps;
            //rayOrigin = rayOrigin + rayDirection * 1.5;
            //stepSize = 1.5/numSteps;
            rayDirection *= -1.;


            stepSize = 0.4*2.*r/(numSteps*.3);
            for(float i =0.; i< numSteps*.3; i++){
                rayOrigin += (rayDirection*stepSize);
                            
                vec3 samplePos = rayOrigin+offset;
                vec2 result = updateIntensity(intensity,disk_intensity,samplePos);
                intensity = result.x;
                disk_intensity = result.y;
            }
            stepSize = 0.4*2.*r/(numSteps*.45);
            for(float i =0.; i< numSteps*.45; i++){
                rayOrigin += (rayDirection*stepSize);
                            
                vec3 samplePos = rayOrigin+offset;
                vec2 result = updateIntensity(intensity,disk_intensity,samplePos);
                intensity = result.x;
                disk_intensity = result.y;
            }
            stepSize = 0.2*2.*r/(numSteps*.25);
            for(float i =0.; i< numSteps*.25; i++){
                rayOrigin += (rayDirection*stepSize);
                            
                vec3 samplePos = rayOrigin+offset;
                vec2 result = updateIntensity(intensity,disk_intensity,samplePos);
                intensity = result.x;
                disk_intensity = result.y;
            }

            return vec2(intensity,disk_intensity);
        }

        vec3 pal( in float t, in vec3 a, in vec3 b, in vec3 c, in vec3 d )
        {
            return a + b*cos( 6.28318*(c*t+d) );
        }

        void main() {

            //Disk : galaxyFbmInvert(v_position,7.,0.,1.,1.,2.5)
            //Stars : galaxyFbm(v_position,7.,20.,3.,1.,8.)
            //Dust : galaxyFbmInvert(v_position,8.,0.,1.,1.,3.2)
            //Disk Filament : galaxyFbm(v_position,8.,0.,1.,1.,4.)
            
            vec2 rslt_raymarch = raymarch();

            float intensity = u_galaxyIntensityGlobal*pow(rslt_raymarch.x,u_galaxyIntensityPowFactor);

            float background_intensity = u_backgroundIntensityGlobal*clamp(1.-rslt_raymarch.y,0.,1.)*pow(galaxyFbm(v_position,u_backgroundOctaves,40.,30.,1.,15.*(1.2)),u_backgroundIntensityPowFactor);

            intensity = intensity+background_intensity;

            //vec3 f_color = 4.0*intensity*vec3(193./255.,112./255.,83./255.);

            vec4 f_colorfrag = vec4(vec3(intensity), 1.0);

            return f_colorfrag;
        }
    `;

    static vertexShader = `  

        varying vec3 v_position;

        uniform vec3 u_size;
        uniform vec3 u_camPos;

        void main() {

            v_position = normalize(position);
            return position;
        }
    `;

    constructor(props) {
        super(GalaxyShader, {
          name: "GalaxyShader",
          ...props,
        });
      }
}