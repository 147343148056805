import * as THREE from 'three'
import { extend } from '@react-three/fiber'

class GravViewShader extends THREE.ShaderMaterial {
  constructor() {
    super({
      vertexShader: `
      uniform sampler2D data;
      uniform float u_size;
      uniform vec3 u_seed;
      varying vec3 vPosition;
      varying vec2 vUv;
    
      void main() {
        vPosition = position.xyz;
        vUv = uv;
    
        gl_Position = projectionMatrix * modelViewMatrix * vec4( vec3(position.x,position.y,position.z-0.01), 1.0 );
      }`,
      fragmentShader: `
      uniform sampler2D data;
      varying vec3 vPosition;
      varying vec2 vUv;
      
      vec3 pal( float n, vec3 a, vec3 b, vec3 c, vec3 d )
      {
          return a+b*cos(6.28318*(c*n+d));
      }

      void main() {

        vec3 dat = texture2D(data, vUv.xy).xyz;

        vec3 color = pal(dat.z*.3,vec3(.5,.5,.5),vec3(.5,.5,.5),vec3(1.,1.,1.),vec3(0.,.33,.67)+vec3(0.41));
        if(dat.z <= 0.){
          color = vec3(0.,0.,0.);
        }

        if(dat.z > 100000.){
          color = vec3(0.,1.,0.);
        }

        gl_FragColor = vec4(color,1.0);
      }`,
      uniforms: {
        data: { value: null },
        u_size: {value: 1000},
      },
      transparent: false,
      blending: THREE.NormalBlending,
      depthWrite: true
    })
  }
}

extend({ GravViewShader })
