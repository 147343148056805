import * as THREE from 'three'
import { extend } from '@react-three/fiber'
//import { NoiseChunk, MoreNoiseChunk } from '../../../utils/ShaderChunks'

class GravSimulationShader extends THREE.ShaderMaterial {
  constructor() {

    super({
      vertexShader: `varying vec2 vUv;
      varying vec3 vPosition;
      
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        vPosition = position;
      }`,
      fragmentShader: `
      uniform sampler2D data;

      varying vec2 vUv;
      varying vec3 vPosition;

      const float width = 150.;
      const float height = 150.;

      const float G = 0.0000;
      const float CS = 0.5;
      const float visc = 1.;

      void main() { 
        vec3 dat = texture2D(data, vUv.xy).xyz;
        float RHO = dat.z;
        vec2 U = dat.xy;

        vec2 attraction = vec2(0.);
        if(RHO > 0. && G != 0.){
        for (float x = 0.; x < height; x=x+1.) {
            for (float y = 0.; y < width; y=y+1.) {
              
                
              vec2 xy = vec2(x/width, y/height);
              vec4 pixelData = texture2D(data, xy);
              float rho = pixelData.z;
              if(rho <= 0.){
                continue;
              }


              vec2 v_to = xy-vUv;
              float dist = abs(length(v_to));

              if(dist<1./width*3.){
                continue;
              }

  

              attraction += rho/(dist*dist)*normalize(v_to);

            }
          }
        }
        attraction = G * RHO * attraction;

        vec3 dat_N = texture2D(data, vUv.xy-vec2(1.,0.)/height).xyz;
        vec3 dat_S = texture2D(data, vUv.xy+vec2(1.,0.)/height).xyz;
        vec3 dat_E = texture2D(data, vUv.xy+vec2(0.,1.)/width).xyz;
        vec3 dat_W = texture2D(data, vUv.xy-vec2(0.,1.)/width).xyz;

        vec3 dat_NN = texture2D(data, vUv.xy-vec2(2.,0.)/height).xyz;
        vec3 dat_SS = texture2D(data, vUv.xy+vec2(2.,0.)/height).xyz;
        vec3 dat_EE = texture2D(data, vUv.xy+vec2(0.,2.)/width).xyz;
        vec3 dat_WW = texture2D(data, vUv.xy-vec2(0.,2.)/width).xyz;

        vec3 gradY = (dat_S-dat_N)*.5; 
        vec3 gradX = (dat_E-dat_W)*.5; 

        vec3 gradY_N = (dat-dat_NN)*.5; 
        vec3 gradY_S = (dat_SS-dat)*.5;
        vec3 gradXX = (gradY_S-gradY_N)*.5;

        vec3 gradX_W = (dat-dat_WW)*.5; 
        vec3 gradX_E = (dat_EE-dat)*.5;
        vec3 gradYY = (gradX_E-gradX_W)*.5;

        float drho_dt = -(gradX.z*U.x+gradX.x*RHO)-(gradY.z*U.y+gradY.y*RHO);

        float pas_temps = 0.1;
        float F_UX = U.x ;
        float F_UY = U.y;
        float F_RHO = RHO + drho_dt*pas_temps;

        float dux_dt = - CS*CS * gradX.z  + attraction.x - U.x*gradX.x*RHO - U.y*gradY.x +visc*(gradXX.x+gradYY.x);
        float duy_dt = - CS*CS * gradY.z  + attraction.y - U.x*gradX.y*RHO - U.y*gradY.y +visc*(gradXX.y+gradYY.y);
        F_UX += dux_dt*pas_temps;
        F_UY += duy_dt*pas_temps;
        

        

        gl_FragColor = vec4(F_UX,F_UY,max(F_RHO,0.), 1.);
      }`,
      uniforms: {
        data: { value: null },
      }
    })
  }
}

extend({ GravSimulationShader })
