import { Abstract } from "lamina/vanilla";

export default class AsteroidShader extends Abstract {

    static u_gain = 0.3;
    static u_lacunarity = 2.2;
    static u_frequency = 0.3;
    static u_seed = [0.,0.,0.];
    static u_octaves = 7;

    static fragmentShader = ` 
        varying float v_noise;
        
        vec3 pal( float n, vec3 a, vec3 b, vec3 c, vec3 d )
        {
            return a+b*cos(6.28318*(c*n+d));
        }

        void main() {

            vec3 col = pal(v_noise*.7,vec3(0.5,0.5,0.5),vec3(0.2,0.2,0.2),vec3(0.8,0.8,0.8),vec3(0.3,0.3,0.3));
            vec4 f_colorfrag = vec4(col, 1.0);

            return f_colorfrag;
        }
    `;

    static vertexShader = `  
        varying float v_noise;

        uniform float u_lacunarity;
        uniform float u_gain;
        uniform float u_frequency;
        uniform vec3 u_seed;
        uniform int u_octaves;

        float fbm(vec3 st) {
            // Initial values
            float value = 0.0;
            float amplitude = 3.0;
            float frequency = u_frequency;
            // Loop of octaves
            for (int i = 0; i < u_octaves; i++) {
              value += amplitude * abs(lamina_noise_perlin((st)*frequency+u_seed*0.01));
              frequency *= u_lacunarity;
              amplitude *= u_gain;
            }
            return value;
        }

            vec3 displace(vec3 p) {
                v_noise = (fbm(p)+lamina_noise_worley(lamina_noise_worley(p)*p)) * .4;
                return v_noise*p;
            }

        
            vec3 orthogonal(vec3 v) {
                return normalize(abs(v.x) > abs(v.z) ? vec3(-v.y, v.x, 0.0)
                : vec3(0.0, -v.z, v.y));
            }

            vec3 recalcNormals(vec3 newPos) {
                float offset = 0.001;
                vec3 tangent = orthogonal(normal);
                vec3 bitangent = normalize(cross(normal, tangent));
                vec3 neighbour1 = position + tangent * offset;
                vec3 neighbour2 = position + bitangent * offset;
                vec3 displacedNeighbour1 = displace(neighbour1);
                vec3 displacedNeighbour2 = displace(neighbour2);
                vec3 displacedTangent = displacedNeighbour1 - newPos;
                vec3 displacedBitangent = displacedNeighbour2 - newPos;
                return normalize(cross(displacedTangent, displacedBitangent));
            }
        
        void main() {

            vec3 f_newPosition = displace(position);

            lamina_finalNormal = recalcNormals(f_newPosition);

            return f_newPosition;
        }
    `;

    constructor(props) {
        super(AsteroidShader, {
          name: "AsteroidShader",
          ...props,
        });
      }
}