import { useFrame, extend } from "@react-three/fiber";
import { Fresnel, LayerMaterial } from "lamina";
import {  useRef } from "react";

import JovianPlanetShader from "../shaders/JovianPlanetShader";
extend({ JovianPlanetShader})

export default function JovianView(props){
  const materialRef = useRef()

  const meshRef = useRef()

  useFrame((state) => {
    const { clock } = state;
    materialRef.current.time = clock.getElapsedTime();
    meshRef.current.rotation.z = clock.getElapsedTime()*.0;
  });

  return (
    <>
    <mesh transparent ref={meshRef} {...props} rotation={[0, 0, 0]}>
      <icosahedronGeometry args={[1,20]} />
      <LayerMaterial lighting="lambert" fog={false} flatShading={false}>
        <jovianPlanetShader ref={materialRef} time={0.0} seed={props.body?.seed?.getValue()??[0.,0.,0.]}/>
        {<Fresnel intensity={1.4} color={[0.15,0.15,0.15]} mode="add" />}
      </LayerMaterial>
    </mesh>
    </>
  )
}
