import { OrbitControls } from "@react-three/drei";
import { Canvas, useFrame, extend } from "@react-three/fiber";
import { LayerMaterial, Depth, Fresnel } from "lamina";
import { useMemo, useRef } from "react";
import * as THREE from 'three'

import AsteroidShader from "../shaders/AsteroidShader";
extend({ AsteroidShader });

export default function AsteroidView(props){
  const materialRef = useRef();
  const meshRef = useRef()

  useFrame((state) => {
    const { clock } = state;
    materialRef.current.time = clock.getElapsedTime();
    meshRef.current.rotation.z = clock.getElapsedTime()*.1;
  });

  return (
    <>
    <mesh ref={meshRef} {...props}>
      <icosahedronGeometry args={[1, 40]} />
      <LayerMaterial lighting="lambert" flatShading={true}>
        <asteroidShader ref={materialRef} seed={props.body?.seed?.getValue()??[0.,0.,0.]} time={0.0} lacunarity={2.6} />
      </LayerMaterial>
    </mesh>
    </>
  )
}
