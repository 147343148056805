import {  OrbitControls, Line} from '@react-three/drei'
import * as THREE from 'three'
import { useThree, useLoader, useFrame, extend} from '@react-three/fiber'
import { Bloom, EffectComposer} from '@react-three/postprocessing'
import { useEffect, useRef, useMemo,  Component, useState } from 'react'
import {  getColorByHisTemp } from '../../../utils'
import SystemSkybox from '../SystemScene/SystemSkybox'
import { Planet } from '../../../objects/Planet'

import { Fresnel, LayerMaterial } from "lamina";

import BasePlanetShader from "../BodyScene/shaders/BasePlanetShader";
import AtmosphereShader from "../BodyScene/shaders/AtmosphereShader";
import AtmosphereMaterial from "../../materials/AtmosphereMaterial"
import { Atmosphere } from '../../../objects/Atmosphere'
import { folder,useControls } from 'leva'
import CanvasGUI from '../../utils/CanvasGUI'
import ComponentWidget from '../../utils/widgets/ComponentWidget'
extend({ BasePlanetShader, AtmosphereShader})

function CreatorScenePreView(props){
    //Build scene
    let camera_m = new THREE.PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 0.15, 5000 );
    camera_m.position.set(20, 0, 0);
    camera_m.up.set(0, 0, 1);

    const { gl,scene, camera } = useThree();

    const set = useThree((state) => state.set)
    useEffect(() => {
        set({ camera: camera_m})
      }, [])

      gl.autoClear = true
      gl.clear();
      gl.antialias = true;
      gl.logarithmicDepthBuffer = true;
      gl.setPixelRatio(window.devicePixelRatio);
      gl.toneMapping = THREE.ACESFilmicToneMapping;
      gl.toneMappingExposure = 0.5;

      scene.background = new THREE.Color(0x000000);
    
    return(
      <>
        <OrbitControls
          enableDamping={true} dampingFactor={0.05} screenSpacePanning={false} minDistance={1}
          maxDistance={200} panSpeed={0}
        />
        {props.body != null && <SystemSkybox planet={props.body} reference={props.body.parent} size={1000.} sunColor={getColorByHisTemp(props.body.parent.objects[0].temperature)}/>}
      </>
    )
}

function MoonView(props){
    const materialRef = useRef()
    const atmomaterialRef = useRef()
  
    const meshRef = useRef()
  
    const atmomeshRef = useRef()

    let body = new Planet(null,null)
    let atmosphere = new Atmosphere(body,{})

    const [{terrainOctaves, terrainFrequency, terrainLacunarity, terrainGain,
        cracksOctaves, cracksIntensity, cracksFrequency, cracksWidth,
        pressure, colorAtmo, cratersLevel,
        polarArea, polarColor, polarLatitude, polarIntensity, polarBorder,
        grass, grassColor, grassSlopeMax, grassDepth, grassIntensity,
        oceanColor, oceanFloor, oceanDepth,
        colorA,colorB,colorC,colorD, colorMultiplier, colorMix, colorMixFactor,
        Seed
    }
        
        , setLeva]
     = useControls(() => (
    {

        Seed: [0.,0.,0.],
        Terrain:folder({terrainOctaves: 6,
            terrainFrequency: 1.0,
            terrainLacunarity: 2.5,
            terrainGain: 0.5,}),

        Craters:folder({cratersLevel: 0}),

        Atmosphere:folder({
            pressure: {value: 0,min:0,max:4.,step: 0.1,},
            colorAtmo: {r:0.3*255,g:0.3*255,b:0.3*255,a:1.0}
        }),

        Polar:folder({
            polarArea: false,
            polarColor: {r:255.,g:255.,b:255.,a:1.},
            polarLatitude: 0.7,
            polarIntensity:1.,
            polarBorder:0.,
        }),

        Grass:folder({
            grass: false,
            grassColor: {r:1.,g:1.,b:1.,a:1.},
            grassSlopeMax: 0.,
            grassDepth: false,
            grassIntensity: 1.,
        }),

        Cracks:folder({
            cracksOctaves: 4,
            cracksFrequency: 4.0,
            cracksIntensity: 0.,
            cracksWidth: 0.94,
        }),

        Ocean:folder({
            oceanColor: {r:255,g:255,b:255,a:1.0},
            oceanFloor: 0.0,
            oceanDepth: true
        }),

        Colors:folder({
            colorA: {r:255, g:255, b:255, a:1.0},
            colorB: {r:0., b:0., g:0., a:1.0},
            colorC: {r:255, b:255, g:255, a:1.0},
            colorD: {r:0., b:0., g:0., a:1.0},
            colorMultiplier: 1.0,
            colorMix: {r:0., b:0., g:0., a:1.0},
            colorMixFactor: 0.0,
        })
    })
    )

    atmosphere.pressure = pressure;
    body.atmosphere = atmosphere;

    useFrame((state) => {
      const { clock } = state;
      materialRef.current.time = clock.getElapsedTime();
      atmomaterialRef.current.time = clock.getElapsedTime();
      meshRef.current.rotation.z = clock.getElapsedTime()*.0;
    });

    
  
    return (
      <>
      {<mesh transparent ref={atmomeshRef} {...props} rotation={[0, 0, 0]}>
        <icosahedronGeometry args={[1.2, 60]} />
        <AtmosphereMaterial color={[colorAtmo.r/255,colorAtmo.g/255,colorAtmo.b/255]} pressure={pressure}/>
      </mesh>}
      <mesh transparent ref={meshRef} {...props} rotation={[0, 0, 0]}>
        <icosahedronGeometry args={[1,20]} />
        <LayerMaterial lighting="lambert" fog={false} flatShading={false}>
          <basePlanetShader ref={materialRef} time={0.0} seed={Seed} 
          cratersLevel={cratersLevel}
          cracksOctaves={cracksOctaves} cracksFrequency={cracksFrequency} cracksIntensity={cracksIntensity} cracksWidth={cracksWidth}
          polarColor={[polarColor.r/255,polarColor.g/255,polarColor.b/255]} polarArea={polarArea} polarLatitude={polarLatitude} polarBorder={polarBorder} polarIntensity={polarIntensity}
          grassColor={[grassColor.r/255,grassColor.g/255,grassColor.b/255]} grass={grass} grassSlopeMax={grassSlopeMax} grassDepth={grassDepth} grassIntensity={grassIntensity}
          oceanColor={[oceanColor.r/255,oceanColor.g/255,oceanColor.b/255]} oceanFloor={oceanFloor} oceanDepth={oceanDepth}
          terrainOctaves={terrainOctaves} terrainFrequency={terrainFrequency}  terrainLacunarity={terrainLacunarity}  terrainGain={terrainGain} 
          colorMat2A={[colorMix.r/255,colorMix.g/255,colorMix.b/255]} colorMixFactor={colorMixFactor} colorMat1Multiplier={colorMultiplier} colorMat1A={[colorA.r/255,colorA.g/255,colorA.b/255]} colorMat1B={[colorB.r/255,colorB.g/255,colorB.b/255]} colorMat1C={[colorC.r/255,colorC.g/255,colorC.b/255]} colorMat1D={[colorD.r/255,colorD.g/255,colorD.b/255]} />
          {<atmosphereShader ref={atmomaterialRef} density={body.atmosphere.getAtmosphereDensity()} color={[colorAtmo.r/255,colorAtmo.g/255,colorAtmo.b/255]} mode="add"/>}
          <Fresnel intensity={1.4} color={[colorAtmo.r/255,colorAtmo.g/255,colorAtmo.b/255]} mode="add" />
        </LayerMaterial>
      </mesh>

      
      <CanvasGUI position={[10,10]}> <ComponentWidget title="Save/Load" size={[window.innerWidth/6,window.innerHeight/6]}>
        <DownloadButton cratersLevel={cratersLevel} Seed={Seed}
          cracksOctaves={cracksOctaves} cracksFrequency={cracksFrequency} cracksIntensity={cracksIntensity} cracksWidth={cracksWidth}
           pressure={pressure} colorAtmo={colorAtmo}
          grassColor={grassColor} grass={grass} grassSlopeMax={grassSlopeMax} grassDepth={grassDepth} grassIntensity={grassIntensity}
          polarColor={polarColor} polarArea={polarArea} polarLatitude={polarLatitude} polarBorder={polarBorder} polarIntensity={polarIntensity}
          terrainOctaves={terrainOctaves} terrainFrequency={terrainFrequency}  terrainLacunarity={terrainLacunarity}  terrainGain={terrainGain} 
          colorMix={colorMix} colorMixFactor={colorMixFactor} colorMultiplier={colorMultiplier} colorA={colorA} colorB={colorB} colorC={colorC} colorD={colorD}
          oceanColor={oceanColor} oceanFloor={oceanFloor} oceanDepth={oceanDepth}
          />

        <br/>
        <div style={{color:"white"}}>
        <b>IMPORT FROM JSON</b>: 
        <input type='file' accept='application/json' onChange={
            async event => {
                const json = JSON.parse(await event.target.files[0].text());

                console.log("json", {...json})
                setLeva(json);
            }
        } />
       </div>
       </ComponentWidget>
      </CanvasGUI>
      </>
    )
  }

export default class PlanetCreatorScene extends Component {

    constructor(props){
        super(props);
        this.state = {
            body: props.body !== undefined ? props.body : null,
        }
    }

    render(){
        return(
            <>
                <ambientLight intensity={0.15}/>
                <pointLight intensity={1.} position={[100.,0.,0.]}/>
                <MoonView/>
                <CreatorScenePreView body={this.state.body}/>
                <EffectComposer>
                    <Bloom luminanceThreshold={0.} luminanceSmoothing={0.05} intensity={0.3}/>
                </EffectComposer>
            </>
        )
    }

}

function DownloadButton(props){

    let data = props;
    let jsonData = JSON.stringify(data);

    let file = new Blob([jsonData],{type: 'application/json'});

    return(
        <a href={URL.createObjectURL(file)} download={"planet.json"}> 
            <div style={{color:"white"}}>
            -<b>EXPORT TO JSON</b>-
            </div>
        </a>
    )
}