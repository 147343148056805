import { BLACKBODY_COLORMAP } from './config/physicsData'

export function gaussianRandom(mean=0, stdev=1) {
    let u = 1 - Math.random()
    let v = Math.random()
    let z = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v)

    return z * stdev + mean
}

export function clamp(value, minimum, maximum) {
    return Math.min(maximum, Math.max(minimum, value))
}

export function getColorByHisTemp(temperature){
    let temperature_used = clamp(temperature,1000,29800);
    temperature_used = temperature_used - temperature_used%200;
    return BLACKBODY_COLORMAP[temperature_used];
  }

export function getPointsOnACircle(radius, nbr, offsetX=0, offsetY=0){
    let points = []
    for (let i = 0; i < nbr; i++) {
        points.push([offsetX+radius*Math.cos(Math.PI*2/nbr*i),offsetY+radius*Math.sin(Math.PI*2/nbr*i),0])
    }
    points.push(points[0]);
    return points;
}