import * as THREE from 'three'
import { extend } from '@react-three/fiber'
import { NoiseChunk, MoreNoiseChunk } from '../../../utils/ShaderChunks'

class AreaCreatorSimulationShader extends THREE.ShaderMaterial {
  constructor() {

    super({
      vertexShader: `varying vec2 vUv;
      varying vec3 vPosition;
      
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        vPosition = position;
      }`,
      fragmentShader: `
      uniform sampler2D positions;
      uniform float u_globalFrequency;
      uniform vec3 u_seed;

      uniform int u_terrainOctaves;
      uniform float u_terrainFrequency;
      uniform float u_terrainLacunarity;
      uniform float u_terrainGain;
      uniform float u_terrainExp;
      uniform float u_terrainIntensity;

      uniform int u_ridgeOctaves;
      uniform float u_ridgeIntensity;
      uniform float u_ridgeFrequency;
      uniform float u_ridgeLacunarity;
      uniform float u_ridgeGain;
      uniform float u_ridgeWidth;
      uniform float u_ridgeSmoothstepMax;

      uniform int u_cratersLevel;
      uniform float u_cratersFrequency;
      uniform float u_cratersLimit;
      uniform float u_cratersLacunarity;
      uniform float u_cratersGain;
      uniform float u_cratersIntensity;

      varying vec2 vUv;
      varying vec3 vPosition;


      ${NoiseChunk}
      ${MoreNoiseChunk}

      void main() {

        vec3 seed = u_seed*u_globalFrequency;

        vec3 st = vec3(vUv.x,vUv.y,0.)*u_globalFrequency;
        float noise = 1.;

        noise = fbm(st*noise+seed, u_terrainOctaves, u_terrainFrequency, u_terrainLacunarity, u_terrainGain);
        noise = fbm(noise*vec3(0.3,0.5,0.2)+vec3(0.), u_terrainOctaves, 1., 1.5, 0.6);

        noise *= u_terrainIntensity;
        noise = pow(noise,u_terrainExp);

       float c_noise = u_cratersIntensity*(craters_fbm(st+seed, u_cratersLevel, u_cratersFrequency, u_cratersLacunarity, u_cratersGain, u_cratersLimit)-u_cratersLimit);
        if(u_cratersLevel != 0){noise = noise+c_noise;}

        float rnoise = u_ridgeIntensity*ridge_fbm(st+seed,u_ridgeOctaves,u_ridgeFrequency,u_ridgeLacunarity,u_ridgeGain,u_ridgeWidth);
        rnoise = smoothstep(0.,u_ridgeSmoothstepMax,rnoise);
        noise = noise - rnoise;

        gl_FragColor = vec4(vec3(vPosition.x,vPosition.y,noise), 1.);
      }`,
      uniforms: {
        positions: { value: null },

        u_seed: {value : [0.,0.,0.]},
        u_globalFrequency: {value : 1.05},

        u_terrainOctaves: {value : 1},
        u_terrainFrequency: {value : 1.05},
        u_terrainLacunarity: {value : 1.05},
        u_terrainGain: {value : 1.05},
        u_terrainExp: {value : 1.05},
        u_terrainIntensity: {value : 1.05},

        u_ridgeOctaves: {value : 1},
        u_ridgeIntensity: {value : 1.05},
        u_ridgeFrequency: {value : 1.05},
        u_ridgeLacunarity: {value : 1.05},
        u_ridgeGain: {value : 1.05},
        u_ridgeWidth: {value : 1.05},
        u_ridgeSmoothstepMax: {value : 1.05},

        u_cratersLevel: {value : 0},
        u_cratersFrequency: {value : 1.05},
        u_cratersLimit: {value : 0.4},
        u_cratersLacunarity: {value : 2.},
        u_cratersGain: {value : 0.4},
        u_cratersIntensity: {value : 2.},
      }
    })
  }
}

extend({ AreaCreatorSimulationShader })
