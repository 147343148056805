
import "./App.css"
import { Galaxy } from './objects/Galaxy';
import ParentView from './view/ParentView';

/**async function getGalaxyData() {
  return fetch('./galaxy.json')
      .then((response)=>response.json())
      .then((json)=>{return json});
}
let GALAXY_DATA = await getGalaxyData();*/
let GALAXY = null//new Galaxy(GALAXY_DATA);

function App() {

  return (
    <ParentView galaxy={GALAXY    }/>
  );
}

export default App;